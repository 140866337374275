import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./entry.scss";
import { Link } from "react-router-dom";
import consultIcon from '../../../assets/icon/Icon Edit-23.png';
import announcedIcon from '../../../assets/icon/Icon Edit-20.png';
import searchPlanningIcon from '../../../assets/icon/Icon Edit-21.png';
import fileIcon from '../../../assets/icon/Icon Edit-22.png';

function EntryHome(props) {

    const listItem = [
        {
            id: 1,
            name: 'Tham vấn ý kiến',
            imgUrl: consultIcon,
            link: '/y-kien-du-thao',
        },
        {
            id: 3,
            name: 'Tra cứu quy hoạch',
            imgUrl: announcedIcon,
            link: '/ban-do-quy-hoach/81',
        },
        {
            id: 4,
            name: 'Quản lý hồ sơ',
            imgUrl: fileIcon,
            link: '/tai-lieu',
            hidden: !props.isLogin,
        },
        {
            id: 2,
            name: 'Công bố quy hoạch',
            imgUrl: searchPlanningIcon,
            link: '/cong-bo-quy-hoach',
        },
    ]
    return (
        <div className="container entry_">
            <div className="row content justify-content-between">
                {listItem.map((item, index) => (
                    <div key={index} hidden={item.hidden} className="col-12 col-md-6 col-lg-3 item">
                        <Link to={item.link}>
                            <div className="item-block">
                                <img
                                    src={item.imgUrl}
                                    alt={item.name}
                                    className=""
                                />
                                <span>{item.name}</span>
                            </div>
                        </Link>
                    </div >
                ))}
            </div>
        </div>
    )
}

export default EntryHome