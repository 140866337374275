/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MenuRightView from "../menu-right/menu-right.view";
import * as statementAction from "../../../redux/store/statement/statement.store";
import "./planning-announcement-detail.scss";
import SideBar from "../../../components/sidebar/sidebar.view";
import {
  DomainUserSite,
  DomainAdminSite,
  APIUrlDefault,
  TokenKey,
  getUserInfo,
  removeCookies,
  setCookies,
} from "../../../utils/configuration";
export default function PlanningAnnouncementDetailDesktopView(props) {
  const [statementModel, setStatementModel] = useState();

  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.id) {
      statementAction
        .GetDetailStatement(props.match.params.id)
        .then((res) => res && res.content && setStatementModel(res.content))
        .catch((err) => { });
    }
  }, []);

  return (
    <div className="container">
      <div className="planning-announcement-detail mt-4 mb-4">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-4">
            <div className="planning_map">
              <img src={require("../../../assets/image/ban-do-hanoi.png")} alt="" />
              <div className="img_title"><p>sơ đồ thành phố hà nội</p></div>
            </div>
            <div className="planning_data border">
              <p>Tra cứu, tìm kiếm, trích xuất thông tin trực tuyến, và cơ sở dữ liệu quy hoạch</p>
              <div className="button">
                <a href={`/ban-do-quy-hoach/${statementModel?.planningId}`}>
                  Truy cập bản đồ
                </a>
              </div>
              {statementModel?.statementDocumentFile && (
                <div className="mt-4">
                  <p>Tải về file quyết định</p>
                  <div className="button">
                    <a href={APIUrlDefault + statementModel.statementDocumentFile} download target="_blank" >
                      Tải file
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-8">
            {statementModel && (
              <div className="planning-announcement-content p-3">
                <h5 className="text-center">{statementModel.title}</h5>
                <p
                  className="content-html"
                  dangerouslySetInnerHTML={{ __html: statementModel.content }}
                ></p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
