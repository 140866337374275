/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./header.scss";
import "./mobile.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faSignOutAlt,
  faCheck,
  faTimes,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import UrlCollect from "../../common/url-collect";
import ViLanguage from "../../languages/vi";
import {
  DomainUserSite,
  DomainAdminSite,
  APIUrlDefault,
  TokenKey,
  getUserInfo,
  removeCookies,
  setCookies,
} from "../../utils/configuration";
import * as statementActions from "../../redux/store/statement/statement.store";
import * as appActions from "../../core/app.store";
import * as acountAction from "../../redux/store/account/account.store";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import {
  NotificationMessageType,
} from "../../utils/configuration";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import history from "../../common/history";
import CreateProjectModal from "./create-project/create-project.view";
const LanguageCollect = ViLanguage;

function RenderModalComponent(props) {
  const { infoAcount, isShowUserInfoModal, handleCloseUserInfoModal, onLogout, showLoading } = props;
  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [isShowChangePassword, setIsShowChangePassword] = useState(false);
  const { register, handleSubmit, errors, getValues, watch, clearErrors, setError } = useForm({ mode: "all", reValidateMode: "onBlur" });

  const password = useRef({});
  password.current = watch("password", "");

  const onChangePassword = () => {
    const { password, confirmPassword } = getValues();
    password === confirmPassword ? clearErrors(["confirmPassword"]) : setError("confirmPassword", { type: "validate" })
  }

  const onSubmit = (data) => {
    clearErrors();
    if (!data.oldPassword && !data.password && !file) { isShowChangePassword && setIsShowChangePassword(!isShowChangePassword); handleCloseUserInfoModal(); return };
    if (data.oldPassword && !data.password) {
      setError("password", { type: "validate" });
      return;
    }
    if (data.password && !data.oldPassword) {
      setError("oldPassword", { type: "validate" });
      return;
    }
    showLoading(true);
    const value = {
      id: infoAcount.id,
      currentPassword: data.oldPassword,
      newPassword: data.password,
      file: avatar
    }
    acountAction.UpdateUserAccount(value).then((res) => {
      if (res && res.content) {
        if (data.oldPassword && data.password) {
          ShowNotification(
            viVN.Success.ChangePasswordSuccess,
            NotificationMessageType.Success
          );
        } else {
          ShowNotification(
            viVN.Success.ChangeAvatarSuccess,
            NotificationMessageType.Success
          )
        }
      }
      isShowChangePassword && setIsShowChangePassword(!isShowChangePassword);
      handleCloseUserInfoModal();
      showLoading(false);
    }).catch((err) => {
      showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error)
    })
  }
  const handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setFile(URL.createObjectURL(event.target.files[0]));
      setAvatar(event.target.files[0]);
    }
  }
  const handleOnCloseModal = () => {
    isShowChangePassword && setIsShowChangePassword(!isShowChangePassword);
    handleCloseUserInfoModal();
  }
  useEffect(() => {
    setFile(null);
  }, [isShowUserInfoModal])
  return (
    <Modal
      show={isShowUserInfoModal}
      onHide={handleOnCloseModal}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
      className="modal-user-info"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mb-0">Thông tin người dùng</h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-4 text-center">
              {!file ? <img
                src={(infoAcount.avatar || infoAcount.avatar !== "null") ? APIUrlDefault + infoAcount.avatar : "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"}
                alt="Avatar"
                className="img-fluid rounded-circle img-avatar"
              /> : <img
                src={file ? file : "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"}
                alt="Avatar"
                className="img-fluid rounded-circle img-avatar"
              />
              }
              <div className="position-relative">
                <button className="btn btn-change-avatar w-75 border mt-4 text-uppercase font-weight-bold">
                  Thay ảnh
                </button>

                <input
                  type="file"
                  name="mediaFile"
                  accept=".png, .jpg, .jpeg"
                  className="media-file"
                  onChange={handleChangeImage}
                ></input>
              </div>
            </div>
            <div className="col-8">
              <h5 className="text-uppercase">
                <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
                Thông tin chung
              </h5>
              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Tài khoản</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.email}
                  />
                </div>
              </div>

              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Họ và tên</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.fullName}
                  />
                </div>
              </div>

              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Email</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.email}
                  />
                </div>
              </div>

              <h5 className="text-uppercase cursor-pointer" onClick={() => setIsShowChangePassword(!isShowChangePassword)}>
                <FontAwesomeIcon icon={faKey} size="sm" className="mr-2" />
                Đổi mật khẩu
              </h5>
              {isShowChangePassword && (<div>
                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">Mật khẩu cũ</label>
                  <div className="col-9">
                    <input type="password" name="oldPassword" onChange={() => clearErrors(["oldPassword"])} placeholder="Nhập mật khẩu" ref={register} className="form-control" />
                    {errors.oldPassword && errors.oldPassword.type === "validate" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                </div>

                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">Mật khẩu mới</label>
                  <div className="col-9">
                    <input
                      type="password"
                      ref={register({
                        minLength: 8,
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                      })}
                      onChange={onChangePassword}
                      name="password"
                      className="form-control"
                      placeholder="Nhập mật khẩu mới"
                    />
                    {errors.password && errors.password.type === "validate" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.password && errors.password.type === "minLength" && (
                      <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
                    )}
                    {errors.password && errors.password.type === "pattern" && (
                      <span className="error">
                        Có it nhất 1 ký tự viết thường, hoa và ký tự đặc biệt
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">
                    Nhập lại mật khẩu mới
                  </label>
                  <div className="col-9">
                    <input
                      type="password"
                      ref={register({
                        validate: (value) =>
                          value === password.current || "Mật khẩu không trùng khớp",
                      })}
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Nhập lại mật khẩu mới"
                    />
                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "validate" && (
                        <span className="error">Mật khẩu không khớp</span>
                      )}
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleOnCloseModal} variant="secondary" >
            <FontAwesomeIcon icon={faTimes} size="sm" className="mr-2" />
            Hủy
          </Button>
          <Button type="submit" variant="primary" >
            <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2" />
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: getUserInfo() ? true : false,
      isAdmin: getUserInfo() && getUserInfo().userRole == 'ADMIN',
      userInfo: getUserInfo(),
      planningType: [],
      isShowUserInfoModal: false,
      infoAcount: null,
      modalCreateProject: false,
    };
  }

  componentWillMount() {
    const { isLogin } = this.state;
    if (isLogin){
      return;
    }else {
      removeCookies(TokenKey.returnUrl);
        setCookies(TokenKey.returnUrl, window.location.pathname);
      window.location.replace("/dang-nhap");
    }
  }


  getUserAccountDetail() {
    this.props.showLoading(true);
    acountAction.GetUserAccountDetail().then((res) => {
      this.setState({ infoAcount: res && res.content ? res.content : null })
      this.props.showLoading(false);
    }).catch((err) => {
      this.props.showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error
      );
      console.log("err", err);

    });
  }

  onLogout() {
    removeCookies(TokenKey.token);
    window.location.replace(DomainUserSite);
  }

  //--- User info modal
  handleOpenUserInfoModal = () => {
    this.getUserAccountDetail();
    this.setState({
      isShowUserInfoModal: true,
    });
  };

  handleCloseUserInfoModal = () => {
    this.setState({
      isShowUserInfoModal: false,
    });
  };

  //--- User info modal
  handleOpenModalCreateProject = () => {
    this.setState({
      modalCreateProject: true,
    });
  };

  handleCloseModalCreateProject = () => {
    this.setState({
      modalCreateProject: false,
    });
  };

  render() {
    const isLogin = this.state.isLogin;
    const isAdmin = this.state.isAdmin;
    const getLoginIcon = () => {
      return (
        <svg width="14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user"
          className="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512">
          <path fill="#FFFFFF"
            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
        </svg>
      )
    }

    return (
      <div className="header-container" id="header-map-page-container">
        <div className="container">
          <div className="row">
            <div className="site-logo">
              <Navbar.Brand href="/">
                <img
                  className="logo"
                  src={require("../../assets/image/favicon.png")}
                  alt="Logo"
                />
                <div className="brand-name">
                  <Link to="/">UBND TP. Hà Nội</Link>
                </div>
              </Navbar.Brand>
            </div>
            <Navbar id="site-menu" collapseOnSelect expand="lg" bg="#FF0000" variant="light">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="/">Trang chủ</Nav.Link>
                  <Nav.Link href="/y-kien-du-thao">Tham vấn ý kiến</Nav.Link>
                  <Nav.Link href="/ban-do-quy-hoach/81">Tra cứu quy hoạch</Nav.Link>
                  {/* <NavDropdownMenu title="CSDL Quy hoạch" id="collasible-nav-dropdown">
                  <DropdownSubmenu href="#" title="Quy hoạch cấp quốc gia">
                    <NavDropdown.Item href="/quy-hoach/1">QH Tổng thể quốc gia</NavDropdown.Item>
                    <NavDropdown.Item href="/quy-hoach/2">QH Không gian biển quốc gia</NavDropdown.Item>
                    <NavDropdown.Item href="/quy-hoach/3">QH sử dụng đất quốc gia</NavDropdown.Item>
                    <NavDropdown.Item href="/quy-hoach/4">QH ngành quốc gia</NavDropdown.Item>
                  </DropdownSubmenu>
                  <NavDropdown.Item href="/quy-hoach/5">Quy hoạch vùng</NavDropdown.Item>
                  <NavDropdown.Item href="/quy-hoach/42">Quy hoạch tỉnh</NavDropdown.Item>
                  <NavDropdown.Item href="/quy-hoach/44">Quy hoạch đô thị - Nông thôn</NavDropdown.Item>
                  <NavDropdown.Item href="/quy-hoach/45">Quy hoạch có tính chất Kỹ Thuật, Chuyên Ngành</NavDropdown.Item>
                </NavDropdownMenu> */}
                  {isLogin ? (
                    <Nav.Link href="/tai-lieu">Quản lý hồ sơ</Nav.Link>
                  ) : (
                    <Nav.Link href="/dang-nhap">Quản lý hồ sơ</Nav.Link>
                  )}
                  <NavDropdownMenu title="Tin tức" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/tin-tuc-chung">Tin tức chung</NavDropdown.Item>
                    <NavDropdown.Item href="/qua-trinh-lap-quy-hoach">Quá trình lập quy hoạch</NavDropdown.Item>
                    <NavDropdown.Item href="/video">Video</NavDropdown.Item>
                    <NavDropdown.Item href="/cong-bo-quy-hoach">Công bố quy hoạch</NavDropdown.Item>
                  </NavDropdownMenu>
                  <Nav.Link href="/lien-he">Liên hệ</Nav.Link>
                  <Nav.Link id="nav-split-line"><span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span></Nav.Link>
                  <NavDropdownMenu title={getLoginIcon()} id="collasible-nav-dropdown">
                    {isLogin ? (
                      <React.Fragment>
                        <NavDropdown.Item onClick={() => { history.push(UrlCollect.UserInfo) }}>Tài khoản</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { history.push(UrlCollect.ChangePassword) }} >Đổi mật khẩu</NavDropdown.Item>
                        {/* <NavDropdown.Item onClick={this.handleOpenModalCreateProject} >Tạo quy hoạch</NavDropdown.Item> */}
                        <NavDropdown.Item onClick={this.onLogout} >Đăng xuất</NavDropdown.Item>
                      </React.Fragment>
                    ) : (
                      <NavDropdown.Item href={UrlCollect.Login}>Đăng nhập</NavDropdown.Item>
                    )}
                  </NavDropdownMenu>
                  <Nav.Link id="nav-languages">
                    <img
                      className="flag-vn"
                      src={require("../../assets/images/language-vn.png")}
                      alt="Tiếng Việt"
                    />
                    <img
                      className="flag-uk"
                      src={require("../../assets/images/language-uk.png")}
                      alt="English"
                    />
                    <div className="cb"></div>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        {this.state.infoAcount && (
          <RenderModalComponent infoAcount={this.state.infoAcount} showLoading={this.props.showLoading} onLogout={this.onLogout} isShowUserInfoModal={this.state.isShowUserInfoModal} handleCloseUserInfoModal={this.handleCloseUserInfoModal} />
        )}
        {
          this.state.modalCreateProject && (
            <CreateProjectModal modalCreateProject={this.state.modalCreateProject} handleCloseModalCreateProject={() => this.handleCloseModalCreateProject()}></CreateProjectModal>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderView);
