import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    APIUrlDefault,
} from "../../../utils/configuration";

import { isMobile } from 'react-device-detect';
import "./banner.scss"

function HomeBannerView(props) {
    const { showLoading } = props;
    const [slideShow, setSlideShow] = useState([])

    const settingSlider = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetSlideShow(),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetSlideShow = () => {
        return new Promise((resolve, reject) => {
            props
                .getSlideShow()
                .then((res) => {
                    setSlideShow(res && res.content && res.content.length > 0 ? res.content : []);
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    };

    const getHomeSlider = () => {
        if (isMobile) {
            return (
                <section id="mobile-background" className="mobile-background"></section>
            )
        } else {
            return (
                <div className="home_banner">
                    <Slider {...settingSlider}>
                        {slideShow.map(
                            (item, index) =>
                                item.status && (
                                    <div className="home-map" key={index}>
                                        {/* <a href={item && item.link ? item.link : ""}> */}
                                        <a>
                                            <img src={APIUrlDefault + item.avatar} alt="Map" />
                                        </a>

                                    </div>
                                )
                        )}
                    </Slider>
                </div>
            )
        }
    }
    return (
        getHomeSlider()
    );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            getSlideShow: homePageStore.GetSlideShow,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(HomeBannerView));
