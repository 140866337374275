import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import Pagination from "../../components/pagination/pagination.view";
import { APIUrlDefault } from "../../utils/configuration";
import "@geedmo/yamm/src/yamm.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./list.scss";
import * as newsActions from "../../redux/store/news/news.store";
import SideBar from "../../components/sidebar/sidebar.view";
import dateformat from "dateformat";
import Videos from "../home/videos/videos.view"
const firstLoad = true;

function ListNewsView(props) {
    const { showLoading } = props;
    const [keyword, setKeyword] = useState([]);

    const [currentPageIndexDoing, setCurrentPageIndexDoing] = useState(1);
    const currentPageSizeDoing = 8;
    const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

    const [pageCountDoing, setPageCountDoing] = useState();
    const [listHotNews, setHotNewsModel] = useState([]);
    const [listNews, setListNews] = useState([]);
    const [hotNews, setHotNews] = useState({});
    const sorting = 'CreatedDate desc';

    useEffect(() => {
        onGetData();
    }, [firstLoad]);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetListHotNews({
                pageIndex: 1,
                pageSize: 6,
                sorting: sorting,
            }),
            onGetListNews({
                pageIndex: currentPageIndexDoing,
                pageSize: currentPageSizeDoing,
                sorting: sorting,
            }),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetListNews = (data) => {
        return new Promise((resolve, reject) => {
            newsActions.GetListNews(data).then(
                (res) => {
                    setListNews(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    setTotalItemCountDoing(
                        res && res.content && res.content.totalItemCount
                            ? res.content.totalItemCount
                            : 0
                    );
                    setPageCountDoing(
                        res && res.content && res.content.pageCount
                            ? res.content.pageCount
                            : 0
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListHotNews = (data) => {
        return new Promise((resolve, reject) => {
            newsActions.GetListHotNews(data).then(
                (res) => {
                    setHotNewsModel(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onPagingClick = (data, isClearSearch = false) => {
        onGetListNews({
            pageIndex: data.pageIndex,
            pageSize: currentPageSizeDoing,
            sorting: sorting,
        }
        );
    };

    const showFirstNews = (listHotNews) => {
        let news = listHotNews[0];
        return (
            <div className="col-md-7">
                <div className="first-news-image">
                    <Link to={"/tin-tuc-chi-tiet/" + news.id}>
                        <img
                            src={APIUrlDefault + news.image_Url}
                            onError={(e) =>
                                (e.target.src = require("../../assets/image/no_image.png"))
                            }
                            alt={news.title}
                        />
                    </Link>
                </div>
                <div className="fist-news-info">
                    <div className="first-news-title">
                        <Link to={"/tin-tuc-chi-tiet/" + news.id} className="news-title">{news.title}</Link>
                    </div>
                    <div className="news-date">
                        <img src={require("../../assets/images/calendar.png")} />
                        <span>{dateformat(news.created_date, "dd/mm/yyyy")}</span>
                    </div>
                </div>
            </div>
        )
    }

    const showOtherHotnews = (listHotNews) => {
        return (
            <div className="col-md-5">
                {Array.isArray(listHotNews) && listHotNews.length > 1 && (
                    listHotNews.map((news, index) => (
                        index > 0 && (
                            <div id={index} className="row hot-other-news-title">
                                <div className="col-4 news_image">
                                    <Link to={"/tin-tuc-chi-tiet/" + news.id}>
                                        <img
                                            src={APIUrlDefault + news.image_Url}
                                            onError={(e) =>
                                                (e.target.src = require("../../assets/image/no_image.png"))
                                            }
                                            alt={news.title}
                                        />
                                    </Link>
                                </div>
                                <div className="col-8 news-info">
                                    <div className="news-title">
                                        <Link to={"/tin-tuc-chi-tiet/" + news.id} className="news-title">{news.title}</Link>
                                    </div>
                                    <div className="news-date">
                                        <img src={require("../../assets/images/calendar.png")} />
                                        <span>{dateformat(news.created_date, "dd/mm/yyyy")}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    ))
                )}
            </div>
        )
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-9 news_containner">
                    <div className="row ">
                        <div className="col-md-12 news_title border_botom">
                            <h3 className="title_">Tin tức nổi bật</h3>
                        </div>
                    </div>

                    {Array.isArray(listHotNews) && listHotNews.length > 0 && (
                        <div className="row">
                            {showFirstNews(listHotNews)}
                            {showOtherHotnews(listHotNews)}
                        </div>
                    )}

                    <div className="list-news common-news">
                        <div className="row ">
                            <div className="col-lg-12 col-md-12 col-12 news_title border_botom">
                                <h3 className="title_">Tin tức khác</h3>
                            </div>
                            <div className="cb"></div>
                        </div>
                        <div className="row">
                            {listNews && listNews.length > 0 ? (
                                listNews.map((item, index) => (
                                    <div key={index} className="news-item col-lg-3">
                                        <div className="news-image">
                                            <Link to={"/tin-tuc-chi-tiet/" + item.id}>
                                                <img
                                                    src={APIUrlDefault + item.image_Url}
                                                    onError={(e) =>
                                                        (e.target.src = require("../../assets/image/no_image.png"))
                                                    }
                                                    alt={item.title}
                                                />
                                            </Link>
                                        </div>
                                        <div className="cb"></div>
                                        <div className="news-info">
                                            <Link to={"/tin-tuc-chi-tiet/" + item.id} className="news-title">{item.title}</Link>
                                            <div className="news-date mt-1">
                                                <img src={require("../../assets/images/calendar.png")} />
                                                <span>{dateformat(item.created_date, "dd/mm/yyyy")}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="news-item">Chưa có dữ liệu để hiển thị!</div>
                            )}
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
                                {listNews && listNews.length > 0 && (
                                    <div className="list-footer">
                                        <div
                                            className={`text-center text-result ${pageCountDoing && pageCountDoing > 1 ? "float-left" : ""
                                                }`}
                                        >
                                            <span>Có {totalItemCountDoing} kết quả</span>
                                        </div>
                                        {pageCountDoing && pageCountDoing > 1 && (
                                            <div className="float-right">
                                                <Pagination
                                                    totalItems={totalItemCountDoing}
                                                    currentIndex={currentPageIndexDoing}
                                                    pageSize={currentPageSizeDoing}
                                                    onClick={(pageIndex) => {
                                                        onPagingClick({
                                                            pageIndex: pageIndex
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <Videos />
                    </div>
                </div>
                <div className="col-md-3 sidebar_new">
                    <SideBar />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListNewsView);
