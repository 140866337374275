import React, { useEffect, useState } from "react";
import * as statementStoreAction from "../../redux/store/statement/statement.store";
import { APIUrlDefault } from "../../utils/configuration";
import ServiceLink from "./service-link.view";

import "./sidebar.scss";

function SideBar() {
    const [linkGroup, setLinkGroup] = useState([]);
    const [map, setMap] = useState([]);
    const [dropDownGroup, setDropDownGroup] = useState([]);
    useEffect(() => {
        getAllServiceLink();
    }, [])

    const getAllServiceLink = () => {
        statementStoreAction
            .GetSerivceLink()
            .then(
                (res) => {
                    if (res && res.content && res.content.linkGroup) {
                        let data = [];
                        res.content.linkGroup.map(item => {
                            if(item.displayType === "MAP") {
                                setMap(item.linkService)
                            } else if (item.displayType === "OTHER") {
                                setLinkGroup(item.linkService)
                            } else if (item.displayType !== "IMAGE") {
                                data.push(item);
                            }
                        })
                        setDropDownGroup(data);
                        console.log('data', data)
                    }
                }
            )
            .catch((err) => { });
    }

    return (
        <div className="right_sidebar">
            <div className="sidebar_item">
                <img src={APIUrlDefault + map[0]?.image} alt={map[0]?.name} />
            </div>
            <ServiceLink linkGroup={dropDownGroup}/>
            {/* <div className="iframe">
                <iframe alt="" bordercolor="#000000" frameborder="0" height="192" hspace="0"
                    id="_com_liferay_iframe_web_portlet_IFramePortlet_INSTANCE_rgQLHqaB42tH_iframe"
                    longdesc="" name="_com_liferay_iframe_web_portlet_IFramePortlet_INSTANCE_rgQLHqaB42tH_iframe"
                    onload="_com_liferay_iframe_web_portlet_IFramePortlet_INSTANCE_rgQLHqaB42tH_monitorIframe();"
                    scrolling="no"
                    src="https://tracuuhoso.hochiminhcity.gov.vn/icloudgate/version4/ps/page/bs/statistic/report-frame-tphcm.cpx"
                    title="" vspace="0"
                >
                </iframe>
            </div> */}
            {linkGroup && linkGroup.length > 0 && linkGroup.map((item, index) => (
                <a href={item.link} target="_blank" key={index} className="sidebar_item">
                    {item.image && <img src={APIUrlDefault + item?.image} alt={item?.name} />}
                </a>
            ))}
        </div>
    )
}

export default SideBar;