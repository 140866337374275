import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import Pagination from "../../components/pagination/pagination.view";
import UrlCollect from "../../common/url-collect";
import {
    APIUrlDefault, DomainUserSite,
    NotificationMessageType,
    NotificationPosition,
} from "../../utils/configuration";
import "@geedmo/yamm/src/yamm.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./list.scss";
import * as notificationActions from "../../redux/store/notification/notification.store";
import dateformat from "dateformat";
import SideBar from "../../components/sidebar/sidebar.view";
const firstLoad = true;

function ListNotificationView(props) {
    const { showLoading } = props;
    const [keyword, setKeyword] = useState([]);

    const [currentPageIndexDoing, setCurrentPageIndexDoing] = useState(1);
    const currentPageSizeDoing = 6;
    const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

    const [pageCountDoing, setPageCountDoing] = useState();
    const [listHotNotification, setHotNotificationModel] = useState([]);
    const [listNotification, setListNotification] = useState([]);
    const [hotNotification, setHotNotification] = useState({});
    const sorting = 'CreatedDate desc';

    useEffect(() => {
        onGetData();
    }, [firstLoad]);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            // onGetListHotNotification({
            //     pageIndex: 1,
            //     pageSize: 5,
            //     sorting: sorting,
            // }),
            onGetListNotification({
                pageIndex: currentPageIndexDoing,
                pageSize: currentPageSizeDoing,
                sorting: sorting,
            }),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetListNotification = (data) => {
        return new Promise((resolve, reject) => {
            notificationActions.GetListNotification(data).then(
                (res) => {
                    setListNotification(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    setTotalItemCountDoing(
                        res && res.content && res.content.totalItemCount
                            ? res.content.totalItemCount
                            : 0
                    );
                    setPageCountDoing(
                        res && res.content && res.content.pageCount
                            ? res.content.pageCount
                            : 0
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListHotNotification = (data) => {
        return new Promise((resolve, reject) => {
            notificationActions.GetListHotNotification(data).then(
                (res) => {
                    setHotNotificationModel(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onPagingClick = (data, isClearSearch = false) => {
        onGetListNotification({
            pageIndex: data.pageIndex,
            pageSize: currentPageSizeDoing,
            sorting: sorting,
        }
        );
    };

    return (
        <div className="container announcement">
            <div className="row">
                <div className="col-md-8">
                    {/* <div className="row link-history">
                        <div className="col-12">
                            <a href="/">Trang chủ</a>
                            &nbsp;&raquo;&nbsp;
                            <span>Quá trình lập quy hoạch</span>
                        </div>
                    </div> */}
                    <div className="row border_botom">
                        <div className="col-12">
                            <div className="news_title">
                                <h3 className="title_">Quá trình lập quy hoạch</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row inform-list">
                        {listNotification && listNotification.length > 0 ? (
                            listNotification.map((item, index) => (
                                <div key={index} className="col-12">
                                    <div className="inform-item">
                                        <Link to={"/qua-trinh-lap-quy-hoach/" + item.id} className="new-title">{item.title}</Link>
                                    </div>
                                    <div className="news-date news_border">
                                        <img src={require("../../assets/images/calendar.png")} />
                                        <span>{dateformat(item.created_date, "dd/mm/yyyy")}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
                            {listNotification && listNotification.length > 0 && (
                                <div className="list-footer-2">
                                    <div
                                        className={`text-center text-result ${pageCountDoing && pageCountDoing > 1 ? "float-left" : ""
                                            }`}
                                    >
                                        <span>Có {totalItemCountDoing} kết quả</span>
                                    </div>
                                    {pageCountDoing && pageCountDoing > 1 && (
                                        <div className="float-right">
                                            <Pagination
                                                totalItems={totalItemCountDoing}
                                                currentIndex={currentPageIndexDoing}
                                                pageSize={currentPageSizeDoing}
                                                onClick={(pageIndex) => {
                                                    onPagingClick({
                                                        pageIndex: pageIndex
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 sidebar_new">
                    {/* <div className="header-hot-notification">
                        <div className="title">
                            <Link to='#' className="text-uppercase">
                                MỚI NHẤT
                            </Link>
                        </div>
                    </div>
                    <div className="list-hot-notification">
                        {listHotNotification && listHotNotification.length > 0 ? (
                            listHotNotification.map((item, index) => (
                                <div key={index} className="hot-new-item">
                                    <div className="new-info">
                                        <a href={"/qua-trinh-lap-quy-hoach/" + item.id} className="new-title">{item.title}</a>
                                        <div className="new-date">{item.createDate}</div>
                                    </div>
                                    <div className="cb"></div>
                                </div>
                            ))
                        ) : (
                            <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
                        )}
                        <div className="cb"></div>
                    </div> */}
                    <SideBar />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListNotificationView);
