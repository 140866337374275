import React from "react";
import Modal from "react-bootstrap/Modal";
import LoginDesktop from "./login.view";

function LoginModal(props) {
    const { isOpen, onClose, isPopup } = props;

    return (
        <Modal
            show={isOpen}
            onHide={() => onClose(false)}
            backdrop="static"
            keyboard={true}
            centered
            size="xm"
            className="modal-user-info"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <LoginDesktop isPopup={isPopup} onClose={onClose} />
        </Modal>
    )
}

export default LoginModal;