/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import "./document.scss";
import $ from "jquery";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DocTreeView from './leftmenu/doctree.view';
import dateformat from "dateformat";
import * as documentAction from "../../redux/store/doc/document.store";
import * as planningAction from "../../redux/store/planning/planning.store";
import Pagination from "../../components/pagination/pagination.view";
import * as config from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faDownload, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import FileViewer from "react-file-viewer";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";


function DocumentList(props) {
    const {
        showLoading, folderId, setCurrentFolderId,
        paginationNumber, listDocuments,
        listBredScrums, GetListDocumentByFolder,
        dataSearch, SetDataSearch
    } = props;
    const history = useHistory();

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const { register, handleSubmit } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const currentPageIndex = 1;
    const currentPageSize = config.defaultPagesize;
    const [isShowPreview, setShowPreview] = useState(false);
    const [file, setFile] = useState();
    const [isTable, setIsTable] = useState(true);
    const [isDetail, setIsDetail] = useState(false);
    const [currenFileId, setCurrenFileId] = useState();

    // const { id } = props.match.params;
    const id = params.get('id');
    const fileId = params.get('fileId');

    const [startDate, setStartDate] = useState('');

    // const HeaderContainerHeight = document.getElementsByClassName(
    //     "MuiTreeItem-root"
    //   )[0].offsetHeight;

    useEffect(() => {
        console.log('kjhatfjhdgs', params.get('fileId'))
        console.log('dấddsa', listDocuments)
        let currentFolderId = parseInt(id);
        let currentFileId = fileId ? parseInt(fileId) : null;
        if (currentFolderId > 0) {
            setCurrentFolderId(currentFolderId);
            GetListDocumentByFolder({
                parentId: currentFolderId,
                pageIndex: currentPageIndex,
                pageSize: currentPageSize
            });
        } else {
            setCurrentFolderId(0);
            GetListDocumentByFolder({
                parentId: 0,
                pageIndex: currentPageIndex,
                pageSize: currentPageSize
            });
        }

        if (currentFileId) {
            GetDocumentById(currentFileId);
        }
        SetDataSearch({ 'actionType': 'list' });
    }, []);

    // useEffect(() => {console.log('dáhjgbdash', HeaderContainerHeight)},[HeaderContainerHeight])

    const GetDocumentById = (id) => {
        planningAction.GetDocumentById(id).then(res => {
            if (res.content && res.content.path) {
                onShowFilePreview({ ...res.content, extension: GetFileExtension(res.content.name) })
            }
        })
    }

    const GetFileExtension = (file) => {
        if (file) {
            return file.slice(file.lastIndexOf('.') + 1)
        }
    }

    const doPaging = (pageIndex) => {
        //console.log(dataSearch);
        if (dataSearch.actionType == 'search') {
            dataSearch.pageIndex = pageIndex;
            GetListDocumentByFolder(dataSearch);
        } else {
            GetListDocumentByFolder({
                parentId: folderId,
                pageIndex: pageIndex,
                pageSize: currentPageSize
            });
        }
    }

    const onShowFilePreview = (file) => {
        if (file) {
            history.push({
                search: `?id=${id ? id : ''}&fileId=${file.id}`
            })
            setFile(file);
            setShowPreview(true);
        }
    };

    const onCloseFilePreview = () => {
        history.push({
            search: id ? `?id=${id}`: '' 
        })
        setShowPreview(false);
    }

    const renderMapIcon = (item) => {
        return (
            <a href={`quy-hoach-chi-tiet/${item.resourceId}`}>
                <FontAwesomeIcon
                    icon={faDatabase}
                    className="ml-2"
                    color="#007bff"
                />
                <span>{" Truy cập CSDL"}</span>
            </a>
        )
    }

    const renderFilePreview = (file) => {
        let isSupport = config.FilePreviewSupport(file.extension);
        return (
            <a
                onClick={() => {
                    isSupport
                        ? onShowFilePreview(file)
                        : ShowNotification(
                            viVN.Warning.FilePreviewNotSupport,
                            config.NotificationMessageType.Warning,
                            config.NotificationPosition.Center
                        );
                }}
                className="cursor-pointer"
            >
                <FontAwesomeIcon icon={faEye} />
                {" "}
                <span>Xem trước</span>
            </a>
        );
    };

    const renderFileDownload = (file) => {
        return (
            <a
                className="cursor-pointer"
                onClick={() => downloadFileBinary(file.id, file.name)}
            >
                <FontAwesomeIcon
                    icon={faDownload}
                    className="ml-2"
                />{" "}
                <span>Tải file</span>
            </a>
        )
    }

    const downloadFileBinary = (id, name) => {
        if (!id) return;
        documentAction.DownloadFile(id).then((res) => {
            const blob = new Blob([res], {
                type: 'application/*',
            });
            saveAs(blob, name);
        })
    }

    const onSubmit = (data) => {
        setCurrentFolderId(0);
        data.actionType = 'search';
        data.parentId = 0;
        data.pageIndex = currentPageIndex;
        data.pageSize = config.defaultPagesize;
        data.publishDateFrom = startDate;

        SetDataSearch(data);

        GetListDocumentByFolder(data);
    };


    const renderSearchArea = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row document-search-box">
                    <div className="col-md-3 col-6 mb-3">
                        <DatePicker
                            id="publish-date"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            ref={register}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Ban hành từ ngày"
                        />
                    </div>
                    <div className="col-md-3 col-6 mb-3">
                        <input id="docNumber" name="docNumber" ref={register} placeholder="Số hiệu văn bản" />
                    </div>
                    <div className="col-md-3 col-12 mb-3">
                        <input id="description" name="description" ref={register} placeholder="Từ khóa" />
                    </div>
                    <div className="col-md-3">
                        <button type="submit" className="btn btn-sm btn-success">Tìm kiếm</button>
                        &nbsp;
                        {isShowPreview && (
                            <div
                                className="close-preview-file"
                                onClick={() => setShowPreview(false)}
                            >
                                <svg fontSize={12} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                                    className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 352 512">
                                    <path fill="#FFFFFF"
                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        )
    }

    const renderDocumentRow = (item, index) => {
        const getPreviewBox = () => {
            if (item.path) {
                return (
                    <div className="document-action-list">
                        {renderFilePreview(item)}
                        {renderFileDownload(item)}
                        {item && item.resourceId && item.resourceId > 0 && item.resourceType && item.resourceType == 'PLANNING' && (
                            renderMapIcon(item)
                        )}
                    </div>
                )
            }
        }
        return (
            <tr key={index}>
                <td>
                    <div className="doc-number">
                        {item.docNumber}
                    </div>
                    <div className="doc-publish-date">
                        Ban hành: {' '}
                        {dateformat(item.publishDate, "dd/mm/yyyy")}
                    </div>
                </td>
                <td>
                    {item.description}
                    {getPreviewBox()}
                </td>
            </tr>
        )
    }

    const renderDocumentBox = () => {
        const getDocumentCls = () => {
            if (isShowPreview) {
                return 'col-md-6'
            } else {
                return 'col-md-9'
            }
        }
        return (
            <div className={getDocumentCls()}>
                {renderSearchArea()}
                <div className="row">
                    <div className="col-md-12 doc-table">
                        <table className="table">
                            <thead className="doc-table-header">
                                <tr>
                                    <th width="30%">Số hiệu</th>
                                    <th width="70%">Mô tả, trích yếu nội dung, chi tiết</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listDocuments && Array.isArray(listDocuments) && listDocuments.length > 0 && (
                                    listDocuments.map((document, index) => renderDocumentRow(document, index))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {paginationNumber && paginationNumber.totalItemCount > 0 && (
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
                            <div className="list-footer mb-4">
                                <div className="text-result float-left">
                                    <span className="ml-2">
                                        Có {paginationNumber.totalItemCount} kết quả
                                    </span>
                                </div>
                                <div className="float-right">
                                    <Pagination
                                        totalItems={paginationNumber.totalItemCount}
                                        currentIndex={paginationNumber.pageIndex}
                                        pageSize={paginationNumber.pageSize}
                                        onClick={doPaging}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const renderLeftMenu = (id) => {
        return (
            <div className="col-md-3">
                <div className="document-menu">
                    <DocTreeView
                        id={id}
                    />
                </div>
            </div>
        )
    }

    const renderPreviewBox = () => {
        return (
            <div
                className={`col-12 col-sm-12 col-md-6 col-lg-6 pl-2 pr-2`}
                style={{ height: window.innerHeight }}
            >
                {/* {console.log(file)} */}
                {file ? (
                    <FileViewer
                        key={file.id}
                        fileType={file.extension.toLowerCase()}
                        filePath={config.APIUrlDefault + file.path}
                        errorComponent={
                            <div className="mt-4 d-flex align-items-center justify-content-center">
                                <CancelPresentationIcon className="text-danger mr-1" /> Lỗi
                                đọc file
                            </div>
                        }
                        unsupportedComponent={
                            <div className="mt-4 d-flex align-items-center justify-content-center">
                                <CancelPresentationIcon className="text-danger mr-1" /> File
                                không được hỗ trợ
                            </div>
                        }
                    />
                ) : (
                    <div className="mt-4 d-flex align-items-center justify-content-center">
                        <CancelPresentationIcon className="text-danger mr-1" /> Không có
                        file để hiển thị
                    </div>
                )}
            </div>
        )
    }

    const modalBody = () => (
        <div className="modal_file_preview">
            <div className="modal_header">
                <div className="modal_title">
                    <p>{file.title}</p>
                </div>
                <div className="modal_close" onClick={() => onCloseFilePreview()}>
                    <p>&#10005;</p>
                </div>
            </div>
            <div className="modal_content_block">
            <div className="modal_content">
                {file ? (
                    <FileViewer
                        key={file.id}
                        fileType={file.extension.toLowerCase()}
                        filePath={config.APIUrlDefault + file.path}
                        errorComponent={
                            <div className="mt-4 d-flex align-items-center justify-content-center">
                                <CancelPresentationIcon className="text-danger mr-1" /> Lỗi
                                đọc file
                            </div>
                        }
                        unsupportedComponent={
                            <div className="mt-4 d-flex align-items-center justify-content-center">
                                <CancelPresentationIcon className="text-danger mr-1" /> File
                                không được hỗ trợ
                            </div>
                        }
                    />
                ) : (
                    <div className="mt-4 d-flex align-items-center justify-content-center">
                        <CancelPresentationIcon className="text-danger mr-1" /> Không có
                        file để hiển thị
                    </div>
                )}
            </div>
            </div>
        </div>
    );

    return (
        <div className="document-container">
            <div className="row">
                    {renderLeftMenu(id)}
                    {renderDocumentBox()}
                </div>
            {isShowPreview && (
                <div>
                    {modalBody()}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    listDocuments: state.doc.listDocuments,
    listBredScrums: state.doc.listBredScrums,
    paginationNumber: state.doc.paginationNumber,
    folderId: state.doc.folderId,
    dataSearch: state.doc.dataSearch,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            GetListDocumentByFolder: documentAction.GetListDocumentByFolder,
            setCurrentFolderId: documentAction.setCurrentFolderId,
            SetDataSearch: documentAction.SetDataSearch,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentList);
