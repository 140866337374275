const UrlCollect = {
  Home: "/",
  Intro: "/dieu-khoan-su-dung",
  Planning: "/quy-hoach",
  PlanningByProvince: "/quy-hoach-theo-tinh",
  PlanningProvince: "/quy-hoach-tinh",
  PriorityProject: "/du-an-uu-tien",
  DetailProject: "/du-an-chi-tiet",
  SpecializedPlanning: "/quy-hoach-chuyen-nganh",
  SyntheticPlanning: "/tong-hop-quy-hoach",
  AreaPlanning: "/quy-hoach-vung/",
  ProvincePlanning: "/du-an-quy-hoach-tinh/",
  SpecialPlanning: "/quy-hoach-don-vi-hanh-chinh-kinh-te-dac-biet/",
  GeneralPlanning: "/quy-hoach-chung",
  SubdivisionPlanning: "/quy-hoach-phan-khu",
  DetailedPlanning: "/quy-hoach-chi-tiet",
  PlanningDocument: "/tai-lieu-quy-hoach",
  PlanningAnnouncement: "/cong-bo-quy-hoach",
  News: "/news",
  RecordsManager: "/quan-ly-ho-so",
  Contact: "/lien-he",
  PlanningMapViewDetail: "/ban-do-quy-hoach/",
  ProjectMapViewDetail: "/xem-du-an/",
  ProjectMapViewLocation: "/xem-dia-diem/",
  PlanningMap: "/ban-do-quy-hoach",
  PlanningReport: "/tinh-hinh-quy-hoach",
  PlanningRegionNation: "/quy-hoach-vung-quoc-gia",
  ThongTinQuyHoach: "/thong-tin-quy-hoach",

  DocumentList: "/tai-lieu",

  //-- User
  Login: "/dang-nhap",
  Register: "/dang-ky",
  ForgotPassword: "/quen-mat-khau",
  ConfirmCode: "/xac-nhan",
  ResetPassword: "/dat-lai-mat-khau",

  //-- Xin y kien cong dong
  PleaseConsultTheCommunity: "/y-kien-du-thao",
  PleaseComment: "/xin-y-kien",
  SummaryIdeas: "/y-kien-tong-hop",

  //-- Tin tuc
  NewsList: "/tin-tuc-chung",
  VideosList: "/video",
  PlanningProcess: '/qua-trinh-lap-quy-hoach',
  NewsDetail: "/tin-tuc-chi-tiet",
  InformList: "/thong-bao",
  InformDetail: "/thong-bao-chi-tiet",

  //-- Error
  Page500: '/page-500/',

  //-- Admin
  ProcessBoardPlanning:"/quy-trinh-cong-bo-quy-hoach",

  //-- User Info
  UserInfo: '/tai-khoan',
  QuyHoach: "/quy-hoach",
  ChangePassword: '/doi-mat-khau',
  ProjectInfo: "/du-an/thong-tin"
};

export default UrlCollect