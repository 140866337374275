export default {
  planningMapView: {
    baseMap: "Bản đồ nền",
    toolsMap: "Công cụ bản đồ",
    notUseBaseMap: "Không dùng bản đồ nền",
    mapToolsPanel: {
      viewObjectInfomation: "Xem thông tin đối tượng",
      toggleFullScreen: "Mở rộng/Thu nhỏ",
      zoomIn: "Phóng to bản đồ",
      zoomOut: "Thu nhỏ bản đồ",
      filterInfomation: "Lọc thông tin",
      mapLegend: "Chú giải bản đồ",
      selectAnObject: "Chọn một đối tượng",
      selectMultiObject: "Chọn nhiều đối tượng",
      measuareArea: "Đo diện tích",
      measuareDistance: "Đo khoảng cách",
      moveViewport: "Di chuyển khung nhìn",
      printTheMap: "In bản đồ",
      myPosition: "Xác định vị trí của bạn",
      exportMap: "Xuất bản đồ",
      compare: "So sánh",
      locationMarker: "Đánh dấu vị trí",
    },
    filterInfomationPopup: {
      headTitle: "Tìm kiếm đối tượng",
      labelSelectSearch: "Chọn đối tượng tìm kiếm",
      searchInLine: "Tìm kiếm trên đường",
      searchInArea: "Tìm kiếm trong vùng",
      searchInCircle: "TÌm kiếm trong đường tròn",
      searchInSquare: "Tìm kiếm trong hình chữ nhật",
      searchWithAttribute: "Theo thuộc tính",
      search: "Tìm kiếm",
      delete: "Xóa",
      close: "Đóng",
    },
  },
  planningMapView_guideToUse: "Hướng dẫn sử dụng",
  planningMapView_guideToUse_category: "Danh mục hướng dẫn",
  planningMapView_map: "Bản đồ",
  planningMapView_infomation: "Thông tin",
  planningMapView_relatedPlanning: "Quy hoạch các cấp",
  planningMapView_result: "Kết quả",
  header_homePage: "Trang chủ",
  header_pleaseConsultTheCommunity: "Xin ý kiến cộng đồng",
  header_lookUpPlanning: "Tra cứu quy hoạch",
  header_news: "Tin tức",
  header_planningAnnouncement: "Công bố quy hoạch",
  header_recordsManager: "Quản lý hồ sơ",
  header_Contact: "Liên hệ",
  header_generalPlanning: "Quy hoạch chung",
  header_syntheticPlanning: "Tổng hợp quy hoạch",
  header_subdivisionPlanning: "Quy hoạch phân khu",
  header_detailedPlanning: "Quy hoạch chi tiết",
  header_specializedPlanning: "Quy hoạch chuyên ngành",
  header_administration: "Quản trị",
  header_logout: "Đăng xuất",
  header_logIn: "Đăng Nhập",
  header_account: "Tài khoản",
  header_title: "GIS Vĩnh Long",
  footer_titleName: "Sở kế hoạch và đầu tư Thành phố Hà Nội",
  footer_email: "example@gmail.com",
  footer_email_to: "example@gmail.com",
  footer_address: "Số 258 Võ Chí Công, Xuân La, Tây Hồ, Hà Nội",
  footer_hotline: " 84.024.38256637",
  footer_hotline_tel: " 84.024.38256637",
  footer_fax: "84.024.382",
  footer_note: " Toàn bộ dữ liệu trên trang này chỉ mang tính chất tham khảo, phục vụ quá trình xây dựng dự thảo lập quy hoạch Thành phố Hà Nội. Nghiêm cấm mọi hành vi sử dụng thông tin trên trang vào mục đích khác. Tổ chức cá nhân chịu hoàn toàn trách nhiệm khi vi phạm",
  footer_policy: "Bản quyền thuộc Sở kế hoạch và đầu tư thành phố Hà Nội",
  footer_madeby_title: "Made by Ctech",
  footer_madeby_link: "https://c2tech.vn/cgis-planning.html",
  footer_construction_unit: "Công ty Cổ phần Công nghệ Xây dựng ACUD Việt Nam",
  footer_website: "",
  footer_website_to: "",
  contact_map_center_lat: 22.663812151150196,
  contact_map_center_lng: 106.25812657434678,
  contact_map_marker_lat: 22.663812151150196,
  contact_map_marker_lng: 106.25812657434678,
  contact_map_url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29785.006075818754!2d105.79352553745116!3d21.06763880978716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abfc1f2dd00b%3A0xdf72b1d805e48b50!2zU-G7nyBL4bq_IGhv4bqhY2ggdsOgIMSQ4bqndSB0xrAgSMOgIE7hu5lp!5e0!3m2!1svi!2s!4v1699515241711!5m2!1svi!2s",
  
};
