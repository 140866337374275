/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "../../core/app.store";
import * as notificationActions from "../../redux/store/notification/notification.store";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./detail.scss";
import {Link} from "react-router-dom";
import UrlCollect from "../../common/url-collect";
import SideBar from "../../components/sidebar/sidebar.view";

function InformDetail(props) {
  const { showLoading } = props;

  const [notificationModel, setNotificationModel] = useState([]);
  const [notificationDetail, setInformDetail] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [pagingNotification, setPagingNotification] = useState();
  const pageSize = 10;
  const {id} = props.match.params;

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetListNotification({
        pageIndex: 1,
        pageSize: pageSize
      }),
      onGetInformDetail(id),
    ])
        .then((res) => {
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
        });
  };

  const onGetInformDetail = (id) => {
    return new Promise((resolve,reject) => {
      notificationActions.GetDetailNotification(id).then(
          (res) => {
            setInformDetail(
                res && res.content
            );
            resolve(res);
          },
          (err) => {
            reject(err);
          }
      );

    })
  }

  const onGetListNotification = (data) => {
    return new Promise((resolve, reject) => {
      notificationActions.GetListHotNotification(data).then(
          (res) => {
            setNotificationModel(
                res &&
                res.content &&
                res.content.items
                && res.content.items.length > 0 ? res.content.items: []
            );
            setPagingNotification(res.content);
            setCurrentPage(res.content.pageIndex);
            resolve(res);
          },
          (err) => {
            reject(err);
          }
      );
    });
  }

  const getNextPage = () => {
    let nextPage = 1;
    if(currentPage >0 ){
      nextPage = currentPage + 1;
    }
    onGetListNotification({
      pageIndex: nextPage,
      pageSize: pageSize
    })
  }

  const getPreviousPage = () => {
    let prePage = 1;
    if(currentPage >1){
      prePage = currentPage - 1;
    }
    onGetListNotification({
      pageIndex: prePage,
      pageSize: pageSize
    })
  }

  return (
      <div>
        <section className="notification">
          <div className="container announcement">
              <div className="row">
                  <div className="col-lg-8 detail-title">
                      <div className="notification-title-box">
                          <p className="notification-title" href="#">{notificationDetail.title}</p>
                      </div>
                      <div className="hot-new">
                          <div className="new-date">{notificationDetail.createdDate}</div>
                          <p className="new-desc" dangerouslySetInnerHTML={{ __html: notificationDetail.description }}></p>
                          <p className="new-content" dangerouslySetInnerHTML={{ __html: notificationDetail.content }}></p>
                      </div>
                  </div>
                  <div className="col-lg-4">
                      {/* <div className="header-other-notification">
                          <div className="title">
                              <Link to={UrlCollect.SummaryIdeas} className="text-uppercase">
                                  THÔNG BÁO MỚI NHẤT
                              </Link>
                          </div>
                      </div>
                      {notificationModel && notificationModel.length >0 ? (
                          notificationModel.map((item,index) => (
                              <div key={index} className="hot-new-item">
                                  <div className="new-info">
                                      <a href={"/thong-bao-chi-tiet/" + item.id}  className="new-title">{item.title}</a>
                                      <div className="new-date">{item.createDate}</div>
                                  </div>
                              </div>
                          ))
                      ):(
                          <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
                      )} */}
                      <SideBar />
                  </div>
              </div>
          </div>
        </section>
      </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(InformDetail));
