import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import "@geedmo/yamm/src/yamm.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./videos.scss";
import * as videosActions from "../../../redux/store/videos/videos.store";
import moment from "moment";
import dateformat from "dateformat";

import LightboxVideo from "./Lightbox";
const firstLoad = true;

function VideosView(props) {
    const { showLoading } = props;

    const [currentPageIndexDoing, setCurrentPageIndexDoing] = useState(1);
    const currentPageSizeDoing = 4;
    const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

    const [pageCountDoing, setPageCountDoing] = useState();
    const [listHotVideos, setHotVideosModel] = useState([]);
    const [listVideos, setListVideos] = useState([]);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [detail, setDetail] = useState(false);
    const sorting = 'CreatedDate desc';

    useEffect(() => {
        onGetData();
    }, [firstLoad]);
    const onClickItem = (item) => {
        setDetail(item);
        setLightboxOpen(true)
    }
    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetListVideos({
                pageIndex: currentPageIndexDoing,
                pageSize: currentPageSizeDoing,
                sorting: sorting,
            }),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetListVideos = (data) => {
        return new Promise((resolve, reject) => {
            videosActions.GetListVideos(data).then(
                (res) => {
                    setListVideos(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    setTotalItemCountDoing(
                        res && res.content && res.content.totalItemCount
                            ? res.content.totalItemCount
                            : 0
                    );
                    setPageCountDoing(
                        res && res.content && res.content.pageCount
                            ? res.content.pageCount
                            : 0
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }




    return (
        <div className="container mb-5 mt-5">
            <div className="home_title border_botom">
                <div className="news_title">
                    <h3 className="title_">Video</h3>
                </div>
                <div className="see-more">
                    <a href="/video">Xem thêm &#10095;</a>
                </div>
            </div>

            {
                lightboxOpen && (
                    <LightboxVideo detail={detail} open={lightboxOpen} onClose={() => setLightboxOpen(false)} />
                )
            }
            <div className="list-videos common-videos">
                <div className="row">
                    {listVideos && listVideos.length > 0 ? (
                        listVideos.map((item, index) => (
                            <div key={index} className="videos-item col-md-6 col-lg-3">
                                <div className="videos-image">
                                    <Link onClick={() => onClickItem(item)}>
                                        <img
                                            src={item.youtubeThumbnail}
                                            onError={(e) =>
                                                (e.target.src = require("../../../assets/image/no_image.png"))
                                            }
                                            alt={item.title}
                                        />
                                    </Link>
                                </div>
                                <div className="cb"></div>
                                <div className="videos-info">
                                    <Link onClick={() => onClickItem(item)} className="videos-title">{item.title}</Link>
                                    <div className="news-date">
                                        <img src={require("../../../assets/images/calendar.png")} />
                                        <span>{dateformat(item.created_date, "dd/mm/yyyy")}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="videos-item">Chưa có dữ liệu để hiển thị!</div>
                    )}
                </div>

            </div>

        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideosView);
