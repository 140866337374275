/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import $ from 'jquery';
import "./map-child.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";
import * as documentAction from "../../../redux/store/doc/document.store";
import { Link } from "react-router-dom";
import Folder from '@material-ui/icons/Folder';
import Target from "ol/events/Target";

function RenderMapChild(props) {
    const groupIndex = props.groupIndex;
    const handleGroupClick = (category_id) => {
        var _this = $('#group-sub-folder-' + category_id);
        if (_this.hasClass("group-sub-close")) {
            _this.addClass("group-sub-open").removeClass("group-sub-close");
        } else {
            _this.addClass("group-sub-close").removeClass("group-sub-open");
        }
    }

    const renderLayerGroup = (categoryList) => {
        return (
            <div className="group-box">
                {categoryList && Array.isArray(categoryList) && categoryList.length > 0 && (
                    categoryList.map((category, index) => (
                        <Accordion id={index}>
                            <AccordionSummary>
                                <div id={`group-sub-folder-${category.id}`} onClick={() => handleGroupClick(category.id)} className="group-sub-folder group-sub-close">
                                    {category.folder_name}
                                </div>
                            </AccordionSummary>
                            {renderLayerGroup(category.children)}
                            <AccordionDetails>
                                {category.layer_settings && Array.isArray(category.layer_settings) && category.layer_settings.length > 0 && (
                                    category.layer_settings.map((item, index) => (
                                        renderLayerItem(item, index)
                                    ))
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))
                )}
            </div>
        )
    }

    const renderLayerItem = (item, index) => {
        return (
            <div className="map-item" key={index}>
                <input
                    id={`map-item-${item.id}`}
                    type="checkbox"
                    defaultChecked={item.is_check}
                    onChange={() => { }}
                    data-left-menu-layer-id={item.name}
                    className="mr-1"
                    onClick={() => handleClickCheckbox(item)}
                />
                &nbsp;
                <label htmlFor={`map-item-${item.id}`}>{item.name}</label>
            </div>
        )
    }
    const handleClickCheckbox = (item) => {
        item.is_check = !item.is_check;
        $('#map-item-' + item.id).prop('checked', item.is_check);
        props.toggleLayerAction(item);
        if (isMobile) {
            props.toggleLeftNavBarAction();
        }
    }
    const renderCreateLayerGroup = () => {
        return (
            <Accordion>
                <AccordionSummary>
                    <div className={`map-folder-box map-folder-box${groupIndex}`}>
                        {props.data.folder_name}
                    </div>
                </AccordionSummary>
                {props.groupIndex !== 3 ? (
                    <AccordionDetails>
                        {props.data.children && Array.isArray(props.data.children) && props.data.children.length > 0 && (
                            renderLayerGroup(props.data.children)
                        )}
                        {props.data.layer_settings && Array.isArray(props.data.layer_settings) && props.data.layer_settings.length > 0 && (
                            props.data.layer_settings.map((item, index) => (
                                renderLayerItem(item, index)
                            ))
                        )}
                    </AccordionDetails>
                ) : (
                    <AccordionDetails>
                        {props.documentFolderList.map((item, index) => (
                            <div key={index} className="folder_name">
                                <a href={`/tai-lieu?id=${item.id}`} target="_blank">
                                    <Folder color="inherit"/>
                                    <span>{item.name}</span>
                                </a>
                            </div>
                        ))}
                    </AccordionDetails>
                )}
            </Accordion>
        )
    }
    return (
        <div>
            {renderCreateLayerGroup()}
        </div>
    );
}


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RenderMapChild);
