/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import "./left-menu.scss";
import ViLanguages from "../../../languages/vi";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import LeftMenuModels from "../../../models/planning-map-view/left-menu.model";
import { connect } from "react-redux";
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import MapChild from "./map-child";
import InfoTable from "./info-table";
import {
  BoundariesViewModel, RelatedLayerModel
} from "../../../models/map-data-model-b/new-planning-relation.model";
import InfoPlanning from "./info/planning"
import InfoDocument from "./info/document"
import InfoNote from "./info/note"
import { isMobile } from 'react-device-detect';
import Related from "./related/related"
import * as StatementAction from "../../../redux/store/statement/statement.store";
import * as documentAction from "../../../redux/store/doc/document.store";

const LanguageCollects = ViLanguages;


function LeftMenuView(props: LeftMenuModels.LeftMenuProps) {
  const [isShowMapResult, setShowMapResult] = useState(false);
  const [headerTable, setHeaderTable] = useState<any>();
  const [searchResultData, setSearchResultData] = useState([])
  const [planningId, setPlanningId] = useState(0)
  const [categoryId, setCategoryId] = useState(0)
  const [relatedUrl, setRelatedUrl] = useState('')
  const [layerId, setLayerId] = useState(0)
  const [documentFolderList, setDocumentFolderList] = useState([]);

  useEffect(() => {
    getPlanningById(props.planningId);
    GetAllDocumentFolder();
  }, []);

  const GetAllDocumentFolder = () => {
    documentAction
      .GetAllDocumentFolder()
      .then((result) => {
        if (result && result.content) {
          setDocumentFolderList(
            result.content && result.content.length > 0
              ? result.content
              : []
          );
        }
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    setHeaderTable(props.headerTable);
  }, [props.headerTable]);


  useEffect(() => {
    setInfomation(props.informationForTableResult);
  }, [props.informationForTableResult]);

  function setInfomation(info: any) {
    setSearchResultData(info.data);
    setPlanningId(info.planning_id);
    setLayerId(info.layer_id);
  }

  function getPlanningById(planningId: number) {
    StatementAction.GetDetailPlanningById(planningId).then((res) => {
      if (res && res.content && res.content.categoryId) {
        const categoryId = res.content.categoryId;
        setCategoryId(categoryId);
        if (categoryId == 42 && res.content.provinceId > 0) {
          setRelatedUrl('/quy-hoach-theo-tinh/' + categoryId + '/' + res.content.provinceId);
        } else {
          setRelatedUrl('/quy-hoach/' + categoryId);
        }
      }
    });
  };



  function handleClickIconLeftNavbar(id: string) {
    handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement.classList.contains("collapsed")) buttonElement.click();
  }

  function handleClickToggleLeftNavBar() {
    props.toggleStateIsLeftNavbarHide();
    if (!props.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
    }
  }

  function handleToggleDisplayLayer(
    layerTarget: LayerSettingsModels.LayerSettingsModel
  ) {
    props.openLayerController?.toggleDisplayLayer(layerTarget);
  }

  function handleToggleDisplayBoundaries(
    boundariesObject: BoundariesViewModel,
    relatedLayerObject: RelatedLayerModel,
    isDisplay: boolean
  ) {
    props.openLayerController.toggleDisplayLayerRelated(
      boundariesObject,
      relatedLayerObject,
      isDisplay
    );
  }

  function handleToggleShowAllMapResult() {
    if (!isShowMapResult) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("show-map-result");
      setShowMapResult(true);
    } else {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("show-map-result");
      setShowMapResult(false);
    }
  }

  useEffect(() => {
    props.GetListPlanningRelationByPlanningId(props.planningId);
    if (isMobile) {
      handleClickToggleLeftNavBar();
    }
  }, []);

  return (
    <div
      className={
        "h-100 position-relative custom-container-left-menu" +
        (isShowMapResult ? " show-map-result" : "")
      }
    >
      <div className="navbar-custom h-100 position-relative" id="accordion">
        <div className="header-navbar-custom position-relative">
          <button
            className="btn "
            data-toggle="collapse"
            data-target="#mapLayersTab"
            aria-expanded="true"
            aria-controls="mapLayersTab"
          >
            {LanguageCollects.planningMapView_map}
          </button>
          <button
            id="ButtonControlMapInfomationTab"
            className="btn collapsed"
            data-toggle="collapse"
            data-target="#mapInfomationTab"
            aria-expanded="false"
            aria-controls="mapInfomationTab"
          >
            {LanguageCollects.planningMapView_infomation}
          </button>
          <button
            className="btn collapsed"
            data-toggle="collapse"
            data-target="#relativePlanningTab"
            aria-expanded="false"
            aria-controls="relativePlanningTab"
            hidden={props.isLeftNavbarHide}
          >
            {LanguageCollects.planningMapView_relatedPlanning}
          </button>
          <button
            id="btnRelativeResultTab"
            className="btn collapsed"
            data-toggle="collapse"
            data-target="#relativeResultTab"
            aria-expanded="false"
            aria-controls="relativeResultTab"
            hidden={props.isLeftNavbarHide}
          >
            {LanguageCollects.planningMapView_result}
          </button>
          <button
            className="btn btn-hide-left-navbar position-absolute"
            onClick={() => handleClickToggleLeftNavBar()}
          >
            <FontAwesomeIcon
              icon={props.isLeftNavbarHide ? faAngleRight : faAngleLeft}
              color="white"
              size="lg"
            />
          </button>
        </div>
        <div hidden={props.isLeftNavbarHide}>
          <div
            id="mapLayersTab"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            {props.listGroupLayer.map((data, index: number) => (
              <MapChild
                toggleLayerAction={(
                  data: LayerSettingsModels.LayerSettingsModel
                ) => handleToggleDisplayLayer(data)}
                toggleLeftNavBarAction={() => handleClickToggleLeftNavBar()}
                groupIndex={index}
                data={data}
                documentFolderList={documentFolderList}
              />
            ))}
          </div>
          <div
            id="mapInfomationTab"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <InfoPlanning
              listMapInfomations={props.listMapInfomations}
            />
            <InfoDocument planningId={props.planningId} />
            <InfoNote />
          </div>
          <div
            id="relativePlanningTab"
            className="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordion"
          >
            {props.listPlanningRelationShips?.map((data, index) => (
              <Related
                handleClick={handleToggleDisplayBoundaries}
                key={index}
                data={data}
              />
            ))}
            {props.listPlanningRelationShips?.length === 0 && (
              <p className="p-3 text-center">
                Không có quy hoạch liên quan nào được tìm thấy
              </p>
            )}
          </div>
          <div
            id="relativeResultTab"
            className="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordion"
          >
            {searchResultData && searchResultData.length > 0 ? (
              <InfoTable
                handleClick={handleToggleDisplayBoundaries}
                handleClickShowAllMapResult={handleToggleShowAllMapResult}
                data={searchResultData}
                planningId={planningId}
                layerId={layerId}
                isShowMapResult={isShowMapResult}
                headerTable={props.headerTable ? props.headerTable : []}
              />
            ) : (
              <p className="p-3 text-center">
                Không có kết quả
              </p>
            )}
          </div>
        </div>
        <div
          className="header-navbar-custom-icons-style text-center position-absolute"
          hidden={!props.isLeftNavbarHide}
        >
          <button
            title={LanguageCollects.planningMapView_map}
            onClick={() => handleClickIconLeftNavbar("mapLayersTab")}
          >
            <FontAwesomeIcon icon={faMap} color="#1c40a0" size="lg" />
          </button>
          <button
            title={LanguageCollects.planningMapView_infomation}
            onClick={() => handleClickIconLeftNavbar("mapInfomationTab")}
          >
            <div className="information-circle-warp">
              <img
                src={require("../../../assets/icon/information.svg")}
                alt="Information"
              />
            </div>
          </button>
          <button
            title={LanguageCollects.planningMapView_relatedPlanning}
            onClick={() => handleClickIconLeftNavbar("relativePlanningTab")}
          >
            <img
              src={require("../../../assets/icon/document-duplicate.svg")}
              alt="Document Duplicate"
            />
          </button>
        </div>
        {/* {relatedUrl && (
          <a href={relatedUrl}>
            <div className="related-button">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left"
                className="svg-inline--fa fa-arrow-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
              </svg>
              &nbsp;
              <span>Cơ sở dữ liệu liên quan </span>
            </div>
          </a>
        )} */}

      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  listMapInfomations: state.mapData.infomations,
  informationForTableResult: state.mapData.dataForTable,
  listPlanningRelationShips: state.mapData.planningRelationShips,
  listPlanningBoundaries: state.mapData.listPlanningBoundaries,
  openLayerController: state.openlayer.openLayerController,
  headerTable: state.mapData.headerTable,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetListPlanningRelationByPlanningId:
        MapDataStore.GetListPlanningRelationByPlanningId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuView);
