const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    // api: "https://quyhoachquocgia-api.cgis.asia/",
    api: "https://hanoi-api.cgis.asia/",
    DomainUserSite: "http://localhost:3000",
    DomainAdminSite: "http://localhost:3000",
    KeyMapApi: "89259ef5-85ef-49f0-88c3-aab7212f836d",
    basemapVnUrl: "https://gisapi.tphcm.gov.vn/developers/VBDImagesService.ashx?Type=LoadMapImage&X={x}&Y={y}&Level={z}",
  },
  production: {
    api: "https://hanoi-api.cgis.asia/",
    DomainUserSite: "https://hanoi.cgis.asia",
    DomainAdminSite: "https://admin.hanoi.cgis.asia",
    KeyMapApi: "c6ce4fc5-f0b7-4d72-9f53-e5dfcead11ae",
    basemapVnUrl: "https://gisapi.tphcm.gov.vn/developers/VBDImagesService.ashx?Type=LoadMapImage&X={x}&Y={y}&Level={z}",
  },

  // production: {
  //   api: "https://quyhoachtphcm-api.cgis.asia/",
  //   DomainUserSite:"https://quyhoachtphcm.cgis.asia",
  //   DomainAdminSite:"https://quyhoach-admin.hochiminhcity.gov.vn",
  //   KeyMapApi: "78d6a4da-fa4f-4c28-aa05-509b6295c380",
  //   basemapVnUrl: "https://gisapi.tphcm.gov.vn/developers/VBDImagesService.ashx?Type=LoadMapImage&X={x}&Y={y}&Level={z}",
  // },

};

module.exports = apiEnvironment[env];