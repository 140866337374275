/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEraser,
  faExclamationTriangle,
  faCalendarAlt,
  faVideo,
  faFilm,
  faEye,
  faCamera,
  faQuestionCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as consultantCommunityActions from "../../redux/store/consultant-community/consultant-community.store";
import * as statementActions from "../../redux/store/statement/statement.store";
import * as appActions from "../../core/app.store";
import Pagination from "../../components/pagination/pagination.view";
import UrlCollect from "../../common/url-collect";
import {
  APIUrlDefault,
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import "@geedmo/yamm/src/yamm.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./please-consult-the-community.scss";
import ServiceLink from "../planning-announcement/menu-right/service-link.view";

const firstLoad = true;

function PleaseConsultTheCommunityDesktopView(props) {
  const { showLoading } = props;

  const [doingModel, setDoingModel] = useState([]);
  const [resultModel, setResultModel] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [planningTypeModel, setPlanningTypeModel] = useState("");
  const [approvalLevelModel, setApprovalLevelModel] = useState();

  const [currentPageIndexDoing, setCurrentPageIndexDoing] = useState(1);
  const currentPageSizeDoing = 10;
  const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

  const [currentPageIndexResult, setCurrentPageIndexResult] = useState(1);
  const currentPageSizeResult = 10;
  const [totalItemCountResult, setTotalItemCountResult] = useState(0);

  const [planningTypeOption, setPlanningTypeOption] = useState([]);
  const [approvalLevelOption, setApprovalLevelOption] = useState([]);
  const [pageCountDoing, setPageCountDoing] = useState();
  const [pageCountResult, setPageCountResult] = useState();

  const [isShowSearch, setIsShowSearch] = useState(false);

  const { register, handleSubmit, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onGetData();
  }, [firstLoad]);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetListConsultantCommunity({
        pageIndex: currentPageIndexDoing,
        pageSize: currentPageSizeDoing,
      }),
      onGetPlanningType(),
      onGetApprovalAgencyLevel(),
      onGetListExpiredForm({
        pageIndex: currentPageIndexResult,
        pageSize: currentPageSizeResult,
      }),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetListConsultantCommunity = (data) => {
    return new Promise((resolve, reject) => {
      consultantCommunityActions.GetListConsultantCommunity(data).then(
        (res) => {
          setDoingModel(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items
              : []
          );
          setTotalItemCountDoing(
            res && res.content && res.content.totalItemCount
              ? res.content.totalItemCount
              : 0
          );
          setPageCountDoing(
            res && res.content && res.content.pageCount
              ? res.content.pageCount
              : 0
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onGetListExpiredForm = (data) => {
    return new Promise((resolve, reject) => {
      consultantCommunityActions.GetListResultConsultantCommunity(data).then(
        (res) => {
          setResultModel(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items
              : []
          );
          setTotalItemCountResult(
            res && res.content && res.content.totalItemCount
              ? res.content.totalItemCount
              : 0
          );
          setPageCountResult(
            res && res.content && res.content.pageCount
              ? res.content.pageCount
              : 0
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onGetPlanningType = () => {
    return new Promise((resolve, reject) => {
      statementActions.PlanningType().then(
        (res) => {
          setPlanningTypeOption(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                return { ...item, value: item.id, label: item.name };
              })
              : []
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onGetApprovalAgencyLevel = () => {
    return new Promise((resolve, reject) => {
      statementActions.ApprovalAgencyLevel().then(
        (res) => {
          setApprovalLevelOption(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                return { ...item, value: item.id, label: item.name };
              })
              : []
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const onSearch = (data, isClearSearch = false) => {
    setIsShowSearch(false);
    document.getElementById("dropdownToggle").click();
    showLoading(true);
    setCurrentPageIndexDoing((data && data.pageIndex) || 1);

    let params = {
      pageIndex: (data && data.pageIndex) || 1,
      planningName: (data && data.planningName) || null,
      planningType: (planningTypeModel && planningTypeModel.value) || null,
      approvalLevel: (approvalLevelModel && approvalLevelModel.value) || null,
      place: (data && data.address) || null,
      planningAgency: (data && data.agenciesOrganizations) || null,
      consultingUnit: (data && data.consultingUnit) || null,
      startTime:
        (startDate && moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ")) || null,
      endTime:
        (endDate && moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ")) || null,
    };

    if (data && data.search) {
      params = {
        ...params,
        planningName: data.search,
      };
    }

    if (isClearSearch === true) {
      params = {
        pageIndex: 1,
      };
    }

    consultantCommunityActions
      .GetListConsultantCommunity({ ...params, pageSize: currentPageSizeDoing })
      .then(
        (res) => {
          if (
            res &&
            res.content &&
            res.content.items &&
            res.content.items.length > 0
          ) {
            setDoingModel(res.content.items);
            setTotalItemCountDoing(
              res && res.content && res.content.totalItemCount
                ? res.content.totalItemCount
                : 0
            );
            setPageCountDoing(
              res && res.content && res.content.pageCount
                ? res.content.pageCount
                : 0
            );
          } else {
            ShowNotification(
              viVN.Warning.NoData,
              NotificationMessageType.Warning,
              NotificationPosition.Center
            );
          }
          showLoading(false);
        },
        (err) => {
          showLoading(false);
        }
      );

    consultantCommunityActions
      .GetListResultConsultantCommunity({
        ...params,
        pageSize: currentPageSizeResult,
      })
      .then(
        (res) => {
          setResultModel(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items
              : []
          );
          setTotalItemCountResult(
            res && res.content && res.content.totalItemCount
              ? res.content.totalItemCount
              : 0
          );
          setPageCountResult(
            res && res.content && res.content.pageCount
              ? res.content.pageCount
              : 0
          );
          showLoading(false);
        },
        (err) => {
          showLoading(false);
        }
      );
  };

  const onClearSearch = () => {
    document.getElementById("dropdownToggle").click();
    document.getElementById("searchFormOne").reset();
    document.getElementById("searchFormTwo").reset();
    setStartDate(null);
    setEndDate(null);
    setPlanningTypeModel(null);
    setApprovalLevelModel(null);
    onSearch(null, true);
  };

  const onGetListExpiredFormPaging = (data) => {
    showLoading(true);
    setCurrentPageIndexResult(data.pageIndex || 1);

    let params = {
      pageIndex: data.pageIndex || 1,
      pageSize: currentPageSizeResult,
    };

    consultantCommunityActions.GetListResultConsultantCommunity(params).then(
      (res) => {
        setResultModel(
          res &&
            res.content &&
            res.content.items &&
            res.content.items.length > 0
            ? res.content.items
            : []
        );
        setTotalItemCountDoing(
          res && res.content && res.content.totalItemCount
            ? res.content.totalItemCount
            : 0
        );
        showLoading(false);
      },
      (err) => {
        showLoading(false);
      }
    );
  };

  const handleClickSearchIcon = () => {
    setIsShowSearch(!isShowSearch);
  };

  return (
    <div className="container">
      <div className="community-opinion mt-4 mb-4">
        <div className="row ml-n2 mr-n-2">
          <div className="col-12 col-md-12 col-lg-8 pl-2 pr-2">
            <nav className="yamm navbar navbar-expand header-doing">
              <h6 className="navbar-brand title mb-0">
                QUY HOẠCH ĐANG XIN Ý KIẾN
              </h6>

              <div className="navbar-collapse"></div>

              <div className="dropdown yamm-fw">
                <form id="searchFormOne" onSubmit={handleSubmit(onSearch)}>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="input-search float-right"
                    ref={register}
                    onChange={(event) => setValue("planningName", event.target.value)}
                  />
                </form>

                <FontAwesomeIcon
                  icon={faSearch}
                  className="icon-search dropdown-toggle"
                  onClick={handleClickSearchIcon}
                />

                <div
                  className={
                    "dropdown-menu p-3 " + (isShowSearch ? "show" : "")
                  }
                >
                  <form
                    className="mt-3 form-please-comment"
                    onSubmit={handleSubmit(onSearch)}
                    autoComplete="off"
                    id="searchFormTwo"
                  >
                    <div className="grid mt-2">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 form-group">
                          <label htmlFor="planningName">Tên Quy hoạch</label>
                          <input
                            type="text"
                            name="planningName"
                            id="planningName"
                            className="form-control"
                            placeholder="Nhập tên quy hoạch"
                            ref={register}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 form-group">
                          <label htmlFor="planningType">Loại Quy hoạch</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Chọn quy hoạch"
                            value={planningTypeModel}
                            onChange={(newValue) =>
                              setPlanningTypeModel(newValue)
                            }
                            isClearable={true}
                            name="planningType"
                            options={planningTypeOption}
                            isSearchable={false}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 form-group">
                          <label htmlFor="approvalLevel">Cấp phê duyệt</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Chọn cấp phê duyệt"
                            value={approvalLevelModel}
                            onChange={(newValue) =>
                              setApprovalLevelModel(newValue)
                            }
                            isClearable={true}
                            name="approvalLevel"
                            options={approvalLevelOption}
                            isSearchable={false}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 form-group">
                          <label htmlFor="address">Địa điểm</label>
                          <input
                            type="text"
                            name="address"
                            className="form-control"
                            placeholder="Nhập địa điểm"
                            ref={register}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 form-group">
                          <label htmlFor="agenciesOrganizations">
                            Cơ quan phê duyệt
                          </label>
                          <input
                            type="text"
                            name="agenciesOrganizations"
                            className="form-control"
                            placeholder="Nhập tên cơ quan phê duyệt"
                            ref={register}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 form-group">
                          <label htmlFor="consultingUnit">Đơn vị tư vấn</label>
                          <input
                            type="text"
                            name="consultingUnit"
                            className="form-control"
                            placeholder="Nhập tên đơn vị tư vấn"
                            ref={register}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 form-group">
                          <label htmlFor="startDate">Ngày bắt đầu</label>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Chọn ngày bắt đầu"
                            isClearable={true}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 form-group">
                          <label htmlFor="endDate">Ngày kết thúc</label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Chọn ngày kết thúc"
                            isClearable={true}
                          />
                        </div>

                        <div className="col-12 text-center mt-4 mb-3">
                          <button
                            type="button"
                            className="d-none"
                            id="dropdownToggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <button
                            type="button"
                            className="text-uppercase btn btn-danger mr-2"
                            onClick={onClearSearch}
                          >
                            <FontAwesomeIcon icon={faEraser} className="mr-1" />
                            Xóa kết quả
                          </button>
                          <button
                            type="submit"
                            className="text-uppercase btn btn-info"
                          >
                            <FontAwesomeIcon icon={faSearch} className="mr-1" />
                            Tìm kiếm
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </nav>

            <div className="content-doing">
              {doingModel && doingModel.length > 0 ? (
                doingModel.map((item, index) =>
                  index === 0 ? (
                    <div className="first-item pb-3 mb-4" key={item.id}>
                      <Link to={UrlCollect.PleaseComment + "/" + item.id}>
                        <img
                          src={APIUrlDefault + item.avatar}
                          alt={item.planningName}
                          className="img-fluid w-100"
                        />
                      </Link>

                      <h5 className="mt-2 mb-2">
                        <Link
                          to={UrlCollect.PleaseComment + "/" + item.id}
                          className="title"
                        >
                          {item.planningName}
                        </Link>
                      </h5>

                      <div className="row no-gutters">
                        <div className="col-12 col-md-12 col-lg-12">
                          <p className="mb-2">{item.title}</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                          <b>Ngày bắt đầu: </b>
                          {moment(new Date(item.startTime)).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                          <b>Ngày hết hạn: </b>
                          {moment(new Date(item.endTime)).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="item" key={item.id}>
                      <div className="row">
                        <div className="col-12 col-md-5 col-lg-5">
                          <Link to={UrlCollect.PleaseComment + "/" + item.id}>
                            <img
                              src={APIUrlDefault + item.avatar}
                              alt={item.planningName}
                              className="img-fluid w-100 mb-3 mb-md-0"
                            />
                          </Link>
                        </div>
                        <div className="col-12 col-md-7 col-lg-7 pl-3 pl-md-0">
                          <h5 className="mb-2">
                            <Link
                              to={UrlCollect.PleaseComment + "/" + item.id}
                              className="title"
                            >
                              {item.planningName}
                            </Link>
                          </h5>

                          <div className="row no-gutters">
                            <div className="col-12 col-md-12 col-lg-12">
                              <p className="mb-2">{item.title}</p>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                              <b>Ngày bắt đầu: </b>
                              {moment(new Date(item.startTime)).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                              <b>Ngày hết hạn: </b>
                              {moment(new Date(item.endTime)).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className="text-center text-danger mb-3">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-2"
                  />{" "}
                  Đang trong quá trình lập kế hoạch xin ý kiến!
                </div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-4 pl-2 pr-2">
            <div>
              <div className="header-result">
                <div className="title">
                  <Link to={UrlCollect.SummaryIdeas} className="text-uppercase">
                    KẾT QUẢ XIN Ý KIẾN
                  </Link>
                </div>
              </div>
              <div className="content-result">
                {resultModel && resultModel.length > 0 ? (
                  resultModel.map((item, index) => (
                    <div className="item" key={`result${item.id}`}>
                      <div className="row ml-n2 mr-n2">
                        <div className="col-12 col-md-6 col-lg-6 pl-2 pr-2">
                          <Link
                            to={UrlCollect.SummaryIdeas + "?search=" + item.id}
                          >
                            <img
                              src={item.avatar ? APIUrlDefault + item.avatar : require("../../assets/image/img-default.png")}
                              alt={item.title}
                              className="img-fluid w-100 mb-2 mb-md-0"
                            />
                          </Link>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 pl-2 pr-2">
                          <Link
                            to={UrlCollect.SummaryIdeas + "?search=" + item.id}
                          >
                            {item.planningName}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-danger mb-3">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="mr-2"
                    />{" "}
                    Không có kết quả xin ý kiến nào
                  </div>
                )}
              </div>
              {resultModel && resultModel.length > 0 && (
                <div className="list-footer">
                  <div
                    className={`text-center text-result ${pageCountResult && pageCountResult > 1 ? "float-left" : ""
                      }`}
                  >
                    <span>Có {totalItemCountResult} kết quả</span>
                  </div>
                  {/* {pageCountResult && pageCountResult > 0 && (
                    <div className="float-right">
                      <Pagination
                        totalItems={totalItemCountResult}
                        currentIndex={currentPageIndexResult}
                        pageSize={currentPageSizeResult}
                        onClick={(pageIndex) => {
                          onGetListExpiredFormPaging({
                            pageIndex: pageIndex,
                          });
                        }}
                      />
                    </div>
                  )} */}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-8 d-none d-lg-block">
            {doingModel && doingModel.length > 0 && (
              <div className="list-footer">
                <div
                  className={`text-center text-result ${pageCountDoing && pageCountDoing > 1 ? "float-left" : ""
                    }`}
                >
                  <span>Có {totalItemCountDoing} kết quả</span>
                </div>
                {pageCountDoing && pageCountDoing > 1 && (
                  <div className="float-right">
                    <Pagination
                      totalItems={totalItemCountDoing}
                      currentIndex={currentPageIndexDoing}
                      pageSize={currentPageSizeDoing}
                      onClick={(pageIndex) => {
                        onSearch({
                          pageIndex: pageIndex,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="col-12 col-md-12 col-lg-4"></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PleaseConsultTheCommunityDesktopView);
