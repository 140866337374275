import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import apiConfig from "../../../api/api-config";
import axios from "axios";
const service = new Service();

export const AutoSuggestSearch = async (keyword) => {
    const params = new URLSearchParams();
    params.append("keyword", keyword);
    try {
        const res = await service.post(`${ApiUrl.Autosuggest}?keyword=${keyword}`);
        return res;
    } catch (err) {
        throw err;
    }
};

export const GetLatLngByPlace = async (address) => {
  const params = new URLSearchParams();
  params.append("address", address);
  try {
      const res = await service.post(`${ApiUrl.GetLatLngByPlace}?address=${address}`);
      return res;
  } catch (err) {
      throw err;
  }
};

export const fetchDataFromAPI = async (address) => {
    const apiUrl = "https://gisapi.tphcm.gov.vn/developers/Service/PartnerPortalservice.svc/rest/AutoSuggestSearch";
    const keyword = address;
    const registerKey = "6bb55b31-ef39-496a-8187-8e9f0275bde9";
    console.log('ok')
    try {
      // Gửi yêu cầu POST đến API với header tùy chỉnh và payload JSON
      const response = await axios.post(apiUrl, { Keyword: keyword }, {
        headers: {
          'RegisterKey': registerKey,
          'Content-Type': 'application/json'
        }
      });
  
      // Xử lý phản hồi từ API nếu thành công
      console.log("API Response: ", response.data);
  
      // Trả về dữ liệu từ API để bạn có thể sử dụng nó ở nơi gọi hàm này
      return response.data;
    } catch (error) {
      // Xử lý lỗi nếu có
      console.error("Error: ", error);
      // Nếu bạn muốn xử lý lỗi và trả về một giá trị khác, bạn có thể thay đổi ở đây
      throw error;
    }
  };