import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import * as planningAction from "../../../redux/store/planning/planning.store"
import * as geocodingAction from "../../../redux/store/geocoding/geocoding.store"
import * as appActions from "../../../core/app.store";
import history from "../../../common/history";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import UrlCollect from "../../../common/url-collect";
import latLngIcon from "../../../assets/icon/iconLatLng.png";
import xyIcon from "../../../assets/icon/planning-name.svg";
import locationIcon from "../../../assets/icon/locate.svg";
import addressIcon from "../../../assets/icon/place.svg";
import AsyncSelect from 'react-select/async';
import { NotificationMessageType, NotificationPosition } from "../../../utils/configuration";

var query = null;

function SearchMap(props) {
    const [coord, setCoord] = useState()
    const [currentSearch, setCurrentSearch] = useState('address');
    const [currentPosition, setCurrentPosition] = useState({
        latitude: null,
        longitude: null,
        altitude: null,
        accuracy: null,
        altitudeAccuracy: null,
        heading: null,
        speed: null,
    });

    const listSearchOption = [
        {
            name: 'wgs84',
            tooltip: 'Lat-Lng WGS84',
            imgUrl: latLngIcon,
        },
        {
            name: 'vn2000',
            tooltip: 'X-Y VN2000',
            imgUrl: xyIcon,
        },
        {
            name: 'address',
            tooltip: 'Địa chỉ',
            imgUrl: addressIcon,
        },
    ]


    const handleChangeSearchOption = (option) => {
        setCurrentSearch(option)
    }

    const loadOptions = (inputValue, callback) => {
        if (query) {
            clearTimeout(query);
        }

        if (inputValue.length > 2) {
            query = setTimeout(() => {
                if (inputValue) {
                    const getListAddress = async () => {
                        let results = [];
                        const res = await geocodingAction.AutoSuggestSearch(inputValue);
                        if (res.content && res.content.list && res.content.list.length > 0) {
                            results = res.content.list.map((item, index) => {
                                return { value: index, label: item };
                            })
                        }
                        if (results.length === 0) {
                            ShowNotification(
                                'Vui lòng nhập địa chỉ để tìm kiếm',
                                NotificationMessageType.Warning,
                                'center'
                            );
                        }
                        callback(results);
                    };
                    getListAddress();
                }
            }, 800);
        } else {
            callback([]);
        }
    };

    const handleChoose = (input) => {
        const getLatLng = async () => {
            const res = await geocodingAction.GetLatLngByPlace(input.label);
            if (
                res.content &&
                res.content.value &&
                res.content.value.result &&
                res.content.value.result.latitude
            ) {
                props.checktInPolygon(res.content.value.result.longitude, res.content.value.result.latitude);
            } else {
                ShowNotification(
                    res.err && res.err.errorType,
                    NotificationMessageType.Warning,
                    'center'
                );
            }

        };
        getLatLng();
        // let lat = input.location.lat;
        // let lng = input.location.lng;
        // props.checktInPolygon(lng, lat);
    }

    const pressKey = (event) => {
        if (event.keyCode == 13 && coord) {
            let coordinateList = coord.split(',');
            if (coordinateList && coordinateList.length === 2) {
                let x = parseFloat(coordinateList[0]);
                let y = parseFloat(coordinateList[1]);
                if (currentSearch == 'vn2000') {
                    // GetPlanningByVN2000(x, y)
                    planningAction.GetVn2000ToWgs84(y, x).then(res => {
                        if (res.content === 0) {
                            ShowNotification(
                                viVN.Errors.CoordinateOutSidePolygon,
                                NotificationMessageType.Warning,
                                NotificationPosition.Center
                            );
                        } else {
                            props.checktInPolygon(res[0], res[1]);
                        }
                    })
                } else {
                    // GetPlanningByWgs84(y, x);
                    props.checktInPolygon(y, x);
                }
            } else {
                ShowNotification(
                    'Vui lòng nhập đúng định dạng tọa độ',
                    NotificationMessageType.Warning,
                    'center'
                );
            }
        }
    }


    const GetPlanningByWgs84 = (lng, lat) => {
        planningAction.GetPlanningByWgs84(lng, lat).then(res => {
            if (res.content === 0) {
                ShowNotification(
                    'Không có dự án nào nằm tại vị trí của bạn!',
                    NotificationMessageType.Warning,
                    'center'
                );
            } else {
                return
                // history.push(
                //     `${UrlCollect.PlanningMapViewDetail}${res.content}?lat=${lat}&lng=${lng}&searchType=place`
                // )
            }
        }).catch(err => { console.log(err) })
    }

    const GetPlanningByVN2000 = (x, y) => {
        planningAction.GetPlanningByVN2000(x, y).then(res => {
            if (res.content === 0) {
                ShowNotification(
                    'Không có dự án nào nằm tại vị trí của bạn!',
                    NotificationMessageType.Warning,
                    'center'
                );
            } else {
                return
                // history.push(
                //     `${UrlCollect.PlanningMapViewDetail}${res.content}?lat=${lat}&lng=${lng}&searchType=place`
                // )
            }
        }).catch(err => { console.log(err) })
    }

    const renderInput = () => {
        switch (currentSearch) {
            case 'address':
                return (
                    <AsyncSelect
                        className='search_input_select'
                        placeholder='Nhập địa chỉ tra cứu'
                        isLoading={false}
                        loadOptions={loadOptions}
                        onChange={data => {
                            handleChoose(data);
                        }}
                        components={{ DropdownIndicator: null, LoadingIndicator: null, ClearIndicator: null }}
                        // styles={customStyles}
                        noOptionsMessage={() => 'Nhập địa chỉ để tìm kiếm'}
                    />
                )
                break;
            case 'wgs84':
                return (
                    <input
                        className="search_input_coord"
                        type="text"
                        placeholder="Nhập tọa độ WGS84 (Lat, Lng)"
                        onChange={e => setCoord(e.target.value)}
                        onKeyDown={pressKey}
                    />
                )
                break;
            case 'vn2000':
                return (
                    <input
                        className="search_input_coord"
                        type="text"
                        placeholder="Nhập tọa độ VN2000 (X , Y)"
                        onChange={e => setCoord(e.target.value)}
                        onKeyDown={pressKey}
                    />
                )
                break;
            default:
                return null;
        }
    }

    return (
        <>
            <div className="search_input">
                {renderInput()}
            </div>
            <div className="home_search ismap">
                <div className="search_options">
                    {listSearchOption.map((item, index) => (
                        <div
                            key={index}
                            className={`option_item ${item.name === currentSearch ? 'active' : ''}`}
                            onClick={() => handleChangeSearchOption(item.name)}
                        >
                            <span className="icon_button_wrapper" data-tooltip={item.tooltip}>
                                <img src={item.imgUrl} alt={item.name} />
                            </span>
                        </div>
                    ))}
                </div>
                <div className="search_options">
                    <div
                        className={`option_item location active`}
                        onClick={() => props.handleClickCurrentLocation()}
                    >
                        <span className="icon_button_wrapper" >
                            <div data-tooltip='Vị trí hiện tại'>
                                <img src={locationIcon} alt='Vị trí hiện tại' />
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SearchMap);
