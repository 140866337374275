/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import News from './news/news.view';
import {
  getUserInfo,
} from "../../utils/configuration";
import "./home.scss";
import HomeSearchView from "./search/search.view"
import Videos from "./videos/videos.view";
import HomeBannerView from './banner/banner.view'
import SideBar from "../../components/sidebar/sidebar.view";
import EntryHome from "./entry/entry.view";
import HomeSearch from "./home-search/home-search.view";
import HomeProcessPlanning from "./process-planning-home/process-planning-home.view"

function HomeDesktop(props) {
  const { showLoading } = props;

  const isAdmin = getUserInfo() && getUserInfo().userRole == 'ADMIN';
  const isLogin = getUserInfo() ? true : false;

  return (
    <div className="home-page">
      <div className="container announcement">
        <div className="home_search_container">
          <HomeSearch />
        </div>
      </div>
      <HomeBannerView />
      <div className="container ">
        <div className="row">
          <div className="col-md-9">
            <EntryHome isAdmin={isAdmin} isLogin={isLogin} />
            <HomeProcessPlanning />
            <News />
            <Videos />
          </div>
          <div className="col-md-3">
            <SideBar />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(HomeDesktop));
