/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEraser,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Select from "react-select";
import $ from "jquery";

import * as statementAction from "../../redux/store/statement/statement.store";
import * as appActions from "../../core/app.store";

import UrlCollect from "../../common/url-collect";
import MenuRightView from "./menu-right/menu-right.view";
import * as config from "../../common/config";
import Pagination from "../../components/pagination/pagination.view";
import ShowNotification from "../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import * as viVN from "../../languages/vi-VN.json";
import * as configuration from "../../utils/configuration";
import SideBar from "../../components/sidebar/sidebar.view";
import "./planning-announcement.scss";
import "@geedmo/yamm/src/yamm.scss";

function PlanningAnnouncementDesktopView(props) {
  const { showLoading } = props;
  const [statementModels, setStatementModels] = useState([]);
  const [paginationNumber, setPaginationNumber] = useState();
  const [planningType, setPanningType] = useState([]);
  const [planningTypeSelect, setPanningTypeSelect] = useState(null);
  const [approvalAgencyLevel, setApprovalAgencyLevel] = useState([]);
  const [approvalAgencyLevelSelect, setApprovalAgencyLevelSelect] = useState(
    null
  );
  const [planningTypeId, setPlanningTypeId] = useState();
  const [approvalAgency, setApprovalAgency] = useState();
  const [data, setData] = useState({});

  const [isShowSearch, setIsShowSearch] = useState(false);

  const { register, handleSubmit, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      getListStatementModelsPromise(1, config.Configs.DefaultPageSize, "", {}),
      getPlanningType(),
      getApprovalAgencyLevel(),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const getListStatementModelsPromise = (
    pageIndex,
    pageSize,
    sortExpression = "",
    body
  ) => {
    return new Promise((resolve, reject) => {
      statementAction
        .SearchPlanning(pageIndex, pageSize, sortExpression, body)
        .then((res) => {
          setStatementModels(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items
              : []
          );
          setPaginationNumber(res.content);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const getPlanningType = () => {
    return new Promise((resolve, reject) => {
      statementAction
        .PlanningType()
        .then((res) => {
          setPanningType(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                return { ...item, value: item.id, label: item.name };
              })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const getApprovalAgencyLevel = () => {
    return new Promise((resolve, reject) => {
      statementAction
        .ApprovalAgencyLevel()
        .then((res) => {
          setApprovalAgencyLevel(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                return { ...item, value: item.id, label: item.name };
              })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const getListStatementModels = (
    pageIndex = 1,
    pageSize = config.Configs.DefaultPageSize,
    sortExpression = "",
    body = data
  ) => {
    showLoading(true);
    statementAction
      .SearchPlanning(pageIndex, pageSize, "", body)
      .then((res) => {
        if (
          res &&
          res.content &&
          res.content.items &&
          res.content.items.length > 0
        ) {
          setStatementModels(res.content.items);
          setPaginationNumber(res.content);
          showLoading(false);
        } else {
          showLoading(false);
          ShowNotification(
            viVN.Warning.NoData,
            NotificationMessageType.Warning,
            NotificationPosition.Center
          );
        }
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onStopHiddenDropdown = (e) => {
    $(document).on("click", ".yamm .dropdown-menu", function (e) {
      e.stopPropagation();
    });
  };

  function clearDataForm() {
    setPanningTypeSelect(null);
    setApprovalAgencyLevelSelect(null);
    document.getElementById("dropdownToggle").click();
    document.getElementById("formSearch").reset();
    document.getElementById("search").value = "";
    getListStatementModels(1, config.Configs.DefaultPageSize, "", {});
    setIsShowSearch(false);
  }

  const onSubmit = (data) => {
    setIsShowSearch(false);
    document.getElementById("dropdownToggle").click();
    if (!data) return;
    let body = {
      name: data.planningName,
      planningTypeId: planningTypeId,
      place: data.address,
      consultingUnit: data.consultingUnit,
      numberOfDecisions: data.decisionsNumber,
      yearOfApproval: Number(data.approvalYear),
      approvalAgency: data.approvalAgency,
      planningAgency: data.agenciesOrganizations,
      approvalAgencyLevelId: approvalAgency,
    };
    setData({...data,...body});
    getListStatementModels(1, config.Configs.DefaultPageSize, "", body);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setValue("planningName", event.target.value);
      setData({ ...data, name: event.target.value })  
      showLoading(true);
      statementAction
        .SearchPlanning(1, config.Configs.DefaultPageSize, "", {
          name: event.target.value,
        })
        .then((res) => {
          if (
            res &&
            res.content &&
            res.content.items &&
            res.content.items.length > 0
          ) {
            setStatementModels(res.content.items);
            setPaginationNumber(res.content);
            showLoading(false);
          } else {
            ShowNotification(
              viVN.Warning.NoData,
              NotificationMessageType.Warning,
              NotificationPosition.Center
            );
            showLoading(false);
          }
        })
        .catch((err) => {
          showLoading(false);
        });
    }
  };

  const handleClickSearchIcon = () => {
    setIsShowSearch(!isShowSearch);
  };

  return (
    <div>
      <div className="container announcement">
        <div className="planning-announcement mt-4 mb-4">
          <div className="row ml-n2 mr-n2">
            <div className="col-12 col-md-8 col-lg-8 pl-2 pr-2">
              <nav className="yamm navbar navbar-expand header-doing">
                <h6 className="navbar-brand title mb-0">CÔNG BỐ QUY HOẠCH</h6>

                <div className="navbar-collapse"></div>

                <div className="dropdown yamm-fw">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="input-search float-right"
                    onKeyDown={handleKeyDown}
                    onChange={(event) => setValue("planningName",event.target.value)}
                  />

                  <FontAwesomeIcon
                    icon={faSearch}
                    className="icon-search dropdown-toggle"
                    onClick={handleClickSearchIcon}
                  />

                  <div
                    className={
                      "dropdown-menu p-3 " + (isShowSearch ? "show" : "")
                    }
                  >
                    <div className="grid mt-2">
                      <form onSubmit={handleSubmit(onSubmit)} id="formSearch">
                        <div className="row">
                          <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="planningName">Tên Quy hoạch</label>
                            <input
                              type="text"
                              ref={register}
                              name="planningName"
                              className="form-control"
                              placeholder="Nhập tên quy hoạch"
                            />
                          </div>
                          <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="planningType">Loại Quy hoạch</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Chọn quy hoạch"
                              onBlur={onStopHiddenDropdown}
                              name="planningType"
                              options={planningType}
                              value={planningTypeSelect}
                              onChange={(data) => {
                                setPlanningTypeId((data && data.id) || null);
                                setPanningTypeSelect(data);
                              }}
                              isSearchable={false}
                              isClearable={true}
                            />
                          </div>
                          <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="address">Địa điểm</label>
                            <input
                              type="text"
                              ref={register}
                              name="address"
                              className="form-control"
                              placeholder="Nhập địa điểm"
                            />
                          </div>
                          <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="decisionsNumber">
                              Số quyết định
                            </label>
                            <input
                              type="text"
                              ref={register}
                              name="decisionsNumber"
                              className="form-control"
                              placeholder="Nhập số quyết định"
                            />
                          </div>
                          <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="approvalYear">Năm phê duyệt</label>
                            <input
                              type="text"
                              ref={register}
                              name="approvalYear"
                              onChange={(e) =>
                                setValue(
                                  "approvalYear",
                                  e.target.value.replace(/[^0-9]/g, "")
                                )
                              }
                              className="form-control"
                              placeholder="Nhập năm phê duyệt"
                            />
                          </div>
                          <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="approvalLevel">Cấp phê duyệt</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Chọn cấp phê duyệt"
                              onBlur={onStopHiddenDropdown}
                              name="approvalLevel"
                              value={approvalAgencyLevelSelect}
                              options={approvalAgencyLevel}
                              onChange={(data) => {
                                setApprovalAgency((data && data.id) || null);
                                setApprovalAgencyLevelSelect(data);
                              }}
                              isSearchable={false}
                              isClearable={true}
                            />
                          </div>
                          <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="approvalAgency">
                              Cơ quan phê duyệt
                            </label>
                            <input
                              type="text"
                              ref={register}
                              name="approvalAgency"
                              className="form-control"
                              placeholder="Nhập tên cơ quan phê duyệt"
                            />
                          </div>
                          {/* <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="agenciesOrganizations">
                              Cơ quan phê duyệt
                            </label>
                            <input
                              type="text"
                              ref={register}
                              name="agenciesOrganizations"
                              className="form-control"
                              placeholder="Nhập tên cơ quan/tổ chức lập quy hoạch"
                            />
                          </div> */}
                          <div className="col-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="consultingUnit">
                              Đơn vị tư vấn
                            </label>
                            <input
                              type="text"
                              ref={register}
                              name="consultingUnit"
                              className="form-control"
                              placeholder="Nhập tên đơn vị tư vấn"
                            />
                          </div>
                          <div className="col-12 text-center mt-4 mb-3">
                            <button
                              type="button"
                              className="d-none"
                              id="dropdownToggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            ></button>
                            <button
                              type="button"
                              className="text-uppercase btn btn-danger mr-2"
                              onClick={clearDataForm}
                            >
                              <FontAwesomeIcon
                                icon={faEraser}
                                className="mr-1"
                              />
                              Xóa kết quả
                            </button>
                            <button
                              type="submit"
                              className="text-uppercase btn btn-info"
                            >
                              <FontAwesomeIcon
                                icon={faSearch}
                                className="mr-1"
                              />
                              Tìm kiếm
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </nav>

              <div className="content-doing">
                {statementModels && statementModels.length > 0 ? (
                  statementModels.map((items, index) =>
                    index === 0 ? (
                      <div className="first-item pb-2 mb-2" key={index}>
                        <Link
                          to={UrlCollect.PlanningAnnouncement + "/" + items.id}
                        >
                          <img
                            src={
                              items.avatar
                                ? configuration.APIUrlDefault + items.avatar
                                : require("../../assets/image/img-default.png")
                            }
                            alt="thumbnail"
                            className="img-fluid w-100"
                          />
                        </Link>

                        <h5 className="mt-2 mb-2">
                          <Link
                            to={
                              UrlCollect.PlanningAnnouncement + "/" + items.id
                            }
                            className="title"
                          >
                            {items.title}
                          </Link>
                        </h5>

                        <p>{items.brief}</p>
                      </div>
                    ) : (
                        <div className="item pt-3 mb-2" key={index}>
                          <div className="row">
                            <div className="col-12 col-md-5 col-lg-5">
                              <Link
                                to={
                                  UrlCollect.PlanningAnnouncement + "/" + items.id
                                }
                              >
                                <img
                                  src={
                                    items.avatar
                                      ? configuration.APIUrlDefault + items.avatar
                                      : require("../../assets/image/img-default.png")
                                  }
                                  alt="thumbnail"
                                  className="img-fluid w-100 mb-3 mb-md-0"
                                />
                              </Link>
                            </div>
                            <div className="col-12 col-md-7 col-lg-7 pl-3 pl-md-0">
                              <h5 className="mb-2">
                                <Link
                                  to={
                                    UrlCollect.PlanningAnnouncement +
                                    "/" +
                                    items.id
                                  }
                                  className="title"
                                >
                                  {items.title}
                                </Link>
                              </h5>
                              <p>{items.brief}</p>
                            </div>
                          </div>
                        </div>
                      )
                  )
                ) : (
                    <div className="text-center text-danger mb-3">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-2"
                      />{" "}
                    Không có quy hoạch nào đang được công bố
                    </div>
                  )}
              </div>
              {paginationNumber && paginationNumber.totalItemCount > 0 && (
            <div className="row">
              <div className="col-12 col-md-12 col-lg-8 d-none d-lg-block">
                <div className="list-footer mb-4">
                  <div
                    className={`text-center text-result ${paginationNumber.pageCount > 1 ? "float-left" : ""
                      }`}
                  >
                    <span className="ml-2">
                      Có {paginationNumber.totalItemCount} kết quả
                    </span>
                  </div>
                  {paginationNumber.pageCount > 1 && (
                    <div className="float-right">
                      <Pagination
                        totalItems={paginationNumber.totalItemCount}
                        currentIndex={paginationNumber.pageIndex}
                        pageSize={paginationNumber.pageSize}
                        onClick={getListStatementModels}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
            </div>
            <div className="col-12 col-md-4 announcement_sidebar">
                  <SideBar />
            </div>
            {/* <MenuRightView /> */}
          </div>

          
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningAnnouncementDesktopView);
